import { renderTooltip } from "@/utils/tooltips";
import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { t } from "i18next";
import { OverlayTrigger } from 'react-bootstrap';
import { SuspendResumeTableRow } from "./SuspendResumeTableData";
import { MONITORING_SUSPEND_RESUME_TABLE_HEADER_DATASET_ID, MONITORING_SUSPEND_RESUME_TABLE_HEADER_VERSION, MONITORING_SUSPEND_RESUME_TABLE_HEADER_DATE, MONITORING_SUSPEND_RESUME_TABLE_HEADER_PUBLICATION_STATUS, MONITORING_SUSPEND_RESUME_TABLE_HEADER_SUSPEND_RESUME_STATUS, MONITORING_SUSPEND_RESUME_TABLE_NO_RESULTS, MONITORING_SUSPEND_RESUME_TABLE_DOWNLOAD_NOT_UPDATED, MONITORING_SUSPEND_RESUME_TABLE_BOTH_SEARCH_AND_DOWNLOAD_NOT_UPDATED, MONITORING_SUSPEND_RESUME_TABLE_SEARCH_NOT_UPDATED } from "@/constants/EsapConstants";

interface DataTableProps<TData> {
  data: TData[];
}

export function SuspendResumeTable<TData>({
    data,
}: DataTableProps<TData>) {


const columns =  [
                    {
                        accessorKey: "datasetId",
                        header: t(MONITORING_SUSPEND_RESUME_TABLE_HEADER_DATASET_ID),
                    },
                    {
                        accessorKey: "version",
                        header: t(MONITORING_SUSPEND_RESUME_TABLE_HEADER_VERSION),
                    },
                    {
                        accessorKey: "suspendResumeTimestamp",
                        header: t(MONITORING_SUSPEND_RESUME_TABLE_HEADER_DATE),
                    },
                    {
                        accessorKey: "publicationStatus",
                        header: t(MONITORING_SUSPEND_RESUME_TABLE_HEADER_PUBLICATION_STATUS),
                    },
                    {
                        accessorKey: "suspendResume.status",
                        header: t(MONITORING_SUSPEND_RESUME_TABLE_HEADER_SUSPEND_RESUME_STATUS),
                    }
                ];


const table = useReactTable({
    data,
    columns: columns,
    getCoreRowModel: getCoreRowModel()
});

  return (
    <>
        <div className="table-responsive">
            <table className="table table-sm FSsm">
                <thead className="table-light">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )
                                        }
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                            <>
                                <tr
                                    key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                >
                                    {row.getVisibleCells().map(cell => (
                                        <td key={cell.id}>
                                            {
                                                flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )
                                            }  
                                            {(cell.column.columnDef.header === t(MONITORING_SUSPEND_RESUME_TABLE_HEADER_SUSPEND_RESUME_STATUS) &&
                                                (!(cell.row.original as SuspendResumeTableRow).suspendResume.searchSuspendResumedUpdated || !(cell.row.original as SuspendResumeTableRow).suspendResume.downloadSuspendResumedUpdated)
                                            ) &&
                                                (<OverlayTrigger
                                                    placement="top"
                                                    overlay={renderTooltip(
                                                        (!(cell.row.original as SuspendResumeTableRow).suspendResume.searchSuspendResumedUpdated) ? 
                                                            ((!(cell.row.original as SuspendResumeTableRow).suspendResume.searchSuspendResumedUpdated && (cell.row.original as SuspendResumeTableRow).suspendResume.downloadSuspendResumedUpdated) ? t(MONITORING_SUSPEND_RESUME_TABLE_SEARCH_NOT_UPDATED) : t(MONITORING_SUSPEND_RESUME_TABLE_BOTH_SEARCH_AND_DOWNLOAD_NOT_UPDATED)
                                                        ) :
                                                        t(MONITORING_SUSPEND_RESUME_TABLE_DOWNLOAD_NOT_UPDATED)
                                                    )}
                                                >
                                                    <span className="text-primary"> &#9432;</span>
                                                </OverlayTrigger>)
                                            }                                
                                        </td>
                                    ))}
                                </tr>
                            </>
                        ))
                        ) : (
                            <tr key="noResultsMessage">
                                <td key="noResultsMessageContent" colSpan={columns.length}>
                                {t(MONITORING_SUSPEND_RESUME_TABLE_NO_RESULTS)}
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    </> 
  )
}