import { HistoricalReportResponse, LatestReportResponse, QualityIssuesOfDatasetResponse } from '@/model/MonitoringResults';
import { uuidGenerator } from '@/utils/uuidGenerator';
import { formatDate, formatTime } from '../DateAndTimeFormat';

export type TableDatasetRow = {
    datasetId: string;
}

export const createDatasetRowsFromLatestReportResponse = (response: LatestReportResponse): TableDatasetRow[] => {
    const result: TableDatasetRow[] = [];
    if (response && response.reportId && response.datasetIds.length) {
        response.datasetIds.forEach((datasetId) => {
            const row: TableDatasetRow = {
                datasetId: datasetId
            };
            result.push(row);
        });
    }
    return result;
}

export type TableQualityIssueRow = {
    rowId: string;
    fileTypeCode?: string;
    publicationStatus?: string;
    sealValidation?: string;
    typeOfIssue: string;
    issueTimestampDate?: string;
    issueTimestampTime?: string
    errorMessage?: string;
    status?: string;
    priority?: string;
    acknowledgementStatus?: string;
}

export const createQualityIssueRowsFromResponse = (response: QualityIssuesOfDatasetResponse, reportId: string, datasetId: string): TableQualityIssueRow[] => {
    const result: TableQualityIssueRow[] = [];
    if (response && response.reportId && reportId === response.reportId && response.datasetId && datasetId === response.datasetId && response.qualityIssues?.length) {
        response.qualityIssues.forEach((qualityIssue) => {
            const row: TableQualityIssueRow = {
                rowId: uuidGenerator(), // used for the key property of tr and td elements
                fileTypeCode: response.fileTypeCode,
                publicationStatus: response.publicationStatus,
                sealValidation: response.sealValidation,
                typeOfIssue: qualityIssue.typeOfIssue,
                issueTimestampDate: qualityIssue.issueTimestamp ? formatDate(new Date(qualityIssue.issueTimestamp)) : "",
                issueTimestampTime: qualityIssue.issueTimestamp ? formatTime(new Date(qualityIssue.issueTimestamp)) : "",
                errorMessage: qualityIssue.errorMessage,
                acknowledgementStatus: qualityIssue.acknowledgementStatus,
                priority: qualityIssue.priority,
                status: qualityIssue.status,
            };
            result.push(row);
        });
    }
    return result;
}

export const createDatasetRowsFromHistoricalReportResponse = (response: HistoricalReportResponse): TableDatasetRow[] => {
    const result: TableDatasetRow[] = [];
    if (response && response.reportId && response.datasetIds.length) {
        response.datasetIds.forEach((datasetId) => {
            const row: TableDatasetRow = {
                datasetId: datasetId
            };
            result.push(row);
        });
    }
    return result;
}