const backendSearchUrl: string = import.meta.env.VITE_REACT_APP_BACKEND_SEARCH_URL || "";
const backendCoreUrl: string = import.meta.env.VITE_REACT_APP_BACKEND_CORE_URL || "";
const infoviewerUrl: string = import.meta.env.VITE_REACT_APP_INFOVIEWERURL || "";
const downloadServiceUrl: string = import.meta.env.VITE_REACT_APP_DOWNLOADSERVICEURL || "";
const monitoringUrl: string = import.meta.env.VITE_REACT_APP_MONITORINGURL || "";
const drupalIconsUrl: string = import.meta.env.VITE_REACT_APP_DRUPAL_ICONS_URL|| "";

const ServiceEndpoints = {
    FetchUserPhoto: "https://graph.microsoft.com/v1.0/me/photo/$value"
}

// Log for debugging
console.log('Import.meta.env:', import.meta.env);
console.log('Backend Search URL:', backendSearchUrl);
console.log('Backend Azure URL:', backendCoreUrl);

// Export the backendUrl for use in other parts of your application
export { backendSearchUrl, backendCoreUrl, infoviewerUrl, downloadServiceUrl, monitoringUrl, drupalIconsUrl };

export default ServiceEndpoints;
