import {
    Container,
    Row,
    Col,
    Form,
    Button,
    InputGroup,
    OverlayTrigger,
    ListGroup, Alert,
} from 'react-bootstrap';
import React, {FormEvent, useEffect, useRef, useState} from "react";
import {
    fetchDropdownOptions,
    fetchVersionRelatedDocumentation,
    uploadFileToServer
} from "../../services/ReportingEntitiesService/ReportingEntitiesService.ts";
import {useTranslation} from "react-i18next";
import {
    RE_HEADER_TEXT,
    RE_SYSTEM_VERSION_LABEL, RE_SYSTEM_VERSION_TOOLTIP_TEXT,
    RE_TYPE_OF_DATA_LABEL, RE_TYPE_OF_DATA_TOOLTIP_TEXT,
    RE_VERSION_RELATED_DOCUMENTATION_BUTTON_TEXT
} from "../../constants/EsapConstants.ts";
import {ReportingEntity} from "../../model/ReportingEntity.ts";
import {renderTooltip} from "../../utils/tooltips.tsx";
import {Message, UploadFile} from "../Common/UploadFile.tsx";
interface DocumentationItem {
    title: string;
    body: string;
    field_data_flow: string;
    field_document_type: string;
    field_system_version: string;
    field_environment: string;
    field_applicable_date: string;
    field_applicable_date_1: string;
    field_media_file: string;
}

interface Option {
    name: string;
}

interface DropdownOptions {
    view: Option[];
    view_1: Option[];
}

const ReportingEntities = () => {
    const {t} = useTranslation();
    const [selectedDataType, setSelectedDataType] = useState<string | undefined>();
    const [selectedVersion, setSelectedVersion] = useState<string | undefined>();

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [validated, setValidated] = useState(false);

    const [reportingEntities, setReportingEntities] = useState<ReportingEntity[]>([]);
    const [showHeader, setShowHeader] = useState<boolean>(false);
    const [dataFlowOptions, setDataFlowOptions] = useState<string[]>([]);
    const [systemVersionOptions, setSystemVersionOptions] = useState<string[]>([]);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileValid, setFileValid] = useState<boolean>(true); // Track file validity
    const [message, setMessage] = useState<string>('');
    const [messageType, setMessageType] = useState<'success' | 'danger' | ''>('');
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const handleFileChange = (file: File | null) => {
        if (!file || file.size > 5 * 1024 * 1024 || !file.name.endsWith('.zip')) {
            setMessage('Please select a valid .zip file of size up to 5 MB.');
            setMessageType('danger');
            setFileValid(false);
        } else {
            setMessage('');
            setMessageType('');
            setFileValid(true);
            setSelectedFile(file);
        }
    };

    useEffect(() => {
        fetchDropdownOptions().then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to fetch dropdown options');
            }
        }).then((data: DropdownOptions[]) => {
            if (data.length > 0) {
                setDataFlowOptions(data[0].view.map((option: Option) => option.name));
                setSystemVersionOptions(data[0].view_1.map((option: Option) => option.name));
            }
        }).catch(error => {
            console.error("Error while fetching dropdown options: ", error.message);
        });
    }, []);

    const handleVersionRelatedDocumentationClick = () => {
        if (selectedDataType && selectedVersion) {
            fetchVersionRelatedDocumentation(selectedDataType, selectedVersion)
                .then(response => {
                    if (response.ok) {
                        setShowHeader(true);
                        return response.json();
                    } else {
                        throw new Error('Failed to fetch version related documentation');
                    }
                })
                .then(data => {
                    const transformedData: ReportingEntity[] = data.map((item: DocumentationItem) => ({
                        title: item.title,
                        body: item.body,
                        dataFlow: item.field_data_flow,
                        documentType: item.field_document_type,
                        systemVersion: item.field_system_version,
                        environment: item.field_environment,
                        applicableDateFrom: item.field_applicable_date,
                        applicableDateTo: item.field_applicable_date_1,
                        mediaFile: item.field_media_file
                    }));
                    setReportingEntities(transformedData);
                })
                .catch(error => {
                    console.error(error.message);
                });
        } else {
            console.error('Data Type or Version is not selected');
            setShowHeader(false);
            setReportingEntities([]);
        }
    };

    const handleDataTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedDataType(event.target.value);
    };

    const handleVersionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedVersion(event.target.value);
    };

    const handleMessageChange = (newMessage: Message) => {
        setMessage(newMessage.message);
        setMessageType(newMessage.type);
    };

    const handleUpload = async (event: FormEvent<HTMLFormElement>) => {
        setValidated(true);
        const form = event.currentTarget;
        if (!fileValid || !form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        if (!selectedFile || !selectedDataType || !selectedVersion) {
            setMessage('Please fill in required fields.');
            setMessageType('danger');
            return;
        }

        event.preventDefault();

        setIsUploading(true);

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('dataType', selectedDataType);
        formData.append('version', selectedVersion);

        try {
            const result = await uploadFileToServer(formData, 'TEST_SUBMISSION');

            setMessage(result.message);
            setMessageType(result.success ? 'success' : 'danger');
            setSelectedFile(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        } catch (error) {
            setMessage('An unexpected error occurred.');
            setMessageType('danger');
        } finally {
            setSelectedFile(null);
            setIsUploading(false);
            setValidated(false);
            form.reset();
        }
    };

    return (
        <main>
            <div className="Intro py-2 py-lg-3">
                <Container>
                    <Row>
                        <Col>
                            <h1 className="fs-4 text-center">{t(RE_HEADER_TEXT)}</h1>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Form noValidate validated={validated} onSubmit={handleUpload} className="bg-lighter py-2 py-lg-3">
                <Container>
                    <Row className="py-3">
                        <Col lg={4}>
                            <Form.Group className="FormComponent mb-3" controlId="TSDataType">
                                <Form.Label>{t(RE_TYPE_OF_DATA_LABEL)}</Form.Label>
                                <InputGroup>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={renderTooltip(t(RE_TYPE_OF_DATA_TOOLTIP_TEXT))}
                                    >
                                        <Button
                                            variant="link"
                                            id="TSDataTypeHelp"
                                            className="border-end FormHelp"
                                        >
                                            <i className="bi bi-info-lg" aria-hidden="true"></i>
                                            <span className="visually-hidden">Help with this field</span>
                                        </Button>
                                    </OverlayTrigger>
                                    <Form.Select required={true} aria-describedby="TSDataTypeHelp"
                                                 onChange={handleDataTypeChange}>
                                        <option value="">Select Data Type</option>
                                        {dataFlowOptions.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a data type.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Col>

                        <Col lg={4}>
                            <Form.Group className="FormComponent mb-3" controlId="TSVersion">
                                <Form.Label>{t(RE_SYSTEM_VERSION_LABEL)}</Form.Label>
                                <InputGroup>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={renderTooltip(t(RE_SYSTEM_VERSION_TOOLTIP_TEXT))}
                                    >
                                        <Button
                                            variant="link"
                                            id="TSVersionHelp"
                                            className="border-end FormHelp"
                                        >
                                            <i className="bi bi-info-lg" aria-hidden="true"></i>
                                            <span className="visually-hidden">Help with this field</span>
                                        </Button>
                                    </OverlayTrigger>
                                    <Form.Select required={true} aria-describedby="TSVersionHelp"
                                                 onChange={handleVersionChange}>
                                        <option value="">Select Version</option>
                                        {systemVersionOptions.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a version.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Col>

                        <Col lg={4} className="d-flex align-items-end">
                            <Button variant="link" className="mb-3" onClick={handleVersionRelatedDocumentationClick}>
                                <i className="bi bi-filter-square me-2"></i>
                                {t(RE_VERSION_RELATED_DOCUMENTATION_BUTTON_TEXT)}
                            </Button>
                        </Col>
                    </Row>
                    {/* Insert tag for file upload*/}
                    <UploadFile onFileChange={handleFileChange} onMessageChange={handleMessageChange} isUploading={isUploading}  ref={fileInputRef} isValid={fileValid}></UploadFile>
                    <Row>
                        {message && (
                            <Alert variant={messageType} className="mt-3">
                                {message}
                            </Alert>
                        )}
                    </Row>

                </Container>
            </Form>

            <div className="py-5">
                <Container>
                    <Row>
                        <Col>
                            {showHeader && (
                                <h2 className="fs-4 fw-normal mb-3 text-primary">
                                    Version {selectedVersion} Documentation
                                </h2>
                            )}
                            <ListGroup variant="flush">
                                {reportingEntities.map((entity, index) => (
                                    <ListGroup.Item action href={entity.mediaFile} className="link-primary" key={index}
                                                    style={{ backgroundColor: 'transparent', outline: 'none', boxShadow: 'none' }}
                                                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#E4E4ED')}
                                                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                                                    >
                                        <div className="d-flex">
                                            <i className="bi bi-file-earmark-text fs-4 me-3" aria-hidden="true"></i>
                                            <div className="flex-grow-1">
                                                <span className="pt-2">{entity.title}</span>
                                                <small className="d-block text-body-secondary">{entity.body}</small>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        </main>
    )
}

export default ReportingEntities;