import { DrupalDatasetIcon } from "../../../model/DatasetDetailsIcons";
import { useEffect, useState } from "react";
import { drupalIconsConf } from "../../../services/DatasetDetailsService/DatasetDetailsService";
import { INFOVIEWER_DRUPAL_ICONS_E_SEALED_TOOLTIP, INFOVIEWER_DRUPAL_ICONS_HISTORICAL_LABEL, INFOVIEWER_DRUPAL_ICONS_HISTORICAL_TOOLTIP, INFOVIEWER_DRUPAL_ICONS_VOLUNTARY_LABEL, INFOVIEWER_DRUPAL_ICONS_VOLUNTARY_TOOLTIP } from "@/constants/EsapConstants";
import { t } from "i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface DrupalIconsProps {
  eSealed?: string;
  hstrcData?: boolean;
  vlntry?: boolean;
}

const DrupalIcons: React.FC<DrupalIconsProps> = ({ eSealed, hstrcData, vlntry }) => {
  const [icons, setIcons] = useState<DrupalDatasetIcon[]>([]);
  const [error, setError] = useState<string | null>(null);

  const loadDrupalIcons = async () => {
    const errorMessage = "Failed to fetch icons configurations.";
    try {
      const iconsData = await drupalIconsConf();
      setIcons(iconsData);
    } catch (err) {
      setError(errorMessage);
      console.error("Error fetching icons:", err);
    }
  };

  useEffect(() => {
    loadDrupalIcons();
  }, []);

  const renderIcons = () => {
    const iconElements: JSX.Element[] = [];

    const createIconElement = (
      key: string,
      tooltip: string,
      content: JSX.Element,
      additionalClasses: string = ""
    ) => (
      <OverlayTrigger
        key={key}
        overlay={<Tooltip id={`tooltip-${key}`}>{tooltip}</Tooltip>}
        placement="top"
      >
        <div className={`SRInfo py-2 px-2 px-lg-2 text-info-emphasis ${additionalClasses}`}>
          {content}
        </div>
      </OverlayTrigger>
    );

    if (eSealed != null) {
      const sealIcon = icons.find(icon => icon.name === "UI_SEAL");
      if (sealIcon) {
        iconElements.push(
          createIconElement(
            "eSeal",
            t(INFOVIEWER_DRUPAL_ICONS_E_SEALED_TOOLTIP),
            <>
              <span className="visually-hidden">e-sealed document</span>
              <i className="bi bi-patch-check-fill" aria-hidden="true"></i>
            </>
          )
        );
      }
    }

    if (hstrcData) {
      const historicalIcon = icons.find(icon => icon.name === "UI_HISTORICAL");
      if (historicalIcon) {
        iconElements.push(
          createIconElement(
            "historical",
            t(INFOVIEWER_DRUPAL_ICONS_HISTORICAL_TOOLTIP),
            <small>{t(INFOVIEWER_DRUPAL_ICONS_HISTORICAL_LABEL)}</small>,
            "text-uppercase"
          )
        );
      }
    }

    if (vlntry) {
      const voluntaryIcon = icons.find(icon => icon.name === "UI_VOLUNTARY");
      if (voluntaryIcon) {
        iconElements.push(
          createIconElement(
            "voluntary",
            t(INFOVIEWER_DRUPAL_ICONS_VOLUNTARY_TOOLTIP),
            <small>{t(INFOVIEWER_DRUPAL_ICONS_VOLUNTARY_LABEL)}</small>,
            "text-uppercase"
          )
        );
      }
    }

    return iconElements.length > 0 ? (
      <div className="d-flex justify-content-end align-items-center">
        {iconElements}
      </div>
    ) : null;
  };

  return <>{!error ? renderIcons() : null}</>;
};

export default DrupalIcons;
