export const formatDate = (date: Date): string => {
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
};

export const formatTime = (date: Date): string => {
    const minutes = String(date.getMinutes());
    const hours = String(date.getHours());
    return `${hours}:${minutes}`;
};