import {useState, useRef, FormEvent} from 'react';
import {
    HS_FILE_RE_SUBMISSION, HS_LIST_OF_FILES_TO_BE_REPROCESSED,
    HS_PATH_WHERE_THE_FILES_TO_BE_REPROCESSED_ARE_LOCATED, HS_REPROCESS_FILES, RE_SYSTEM_VERSION_TOOLTIP_TEXT
} from '../../constants/EsapConstants.ts';
import {useTranslation} from 'react-i18next';
import {Message, UploadFile} from '../Common/UploadFile.tsx';
import {Alert, Button, Col, Form, InputGroup, OverlayTrigger, Row} from 'react-bootstrap';
import {uploadFileToServer} from '../../services/ReportingEntitiesService/ReportingEntitiesService.ts';
import {renderTooltip} from "@/utils/tooltips.tsx";


const HistoryOfSubmission = () => {
    const {t} = useTranslation();


    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const handleFileChange = (file: File | null) => {
        if (!file || !file.name.endsWith('.csv')) {
            setMessage('Please select a valid csv file');
            setMessageType('danger');
            setFileValid(false);
        } else {
            setMessage('');
            setMessageType('');
            setFileValid(true);
        }
    };
    const [message, setMessage] = useState<string>('');
    const [messageType, setMessageType] = useState<'success' | 'danger' | ''>('');
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [fileValid, setFileValid] = useState<boolean>(true); // Track file validity
    const [validated, setValidated] = useState(false);

    const handleMessageChange = (newMessage: Message) => {
        setMessage(newMessage.message);
        setMessageType(newMessage.type);
    };

    const handleUpload = async (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        setValidated(true);
        if (!fileValid || !form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        const formData = new FormData(form);
        const selectedFile = formData.get('file') as File;

        if (!selectedFile) {
            setMessage('Please fill in required fields.');
            setMessageType('danger');
            return;
        }

        event.preventDefault();
        setIsUploading(true);

        try {
            const result = await uploadFileToServer(formData, 'RESUBMISSION');
            setMessage(result.message);
            setMessageType(result.success ? 'success' : 'danger');
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        } catch (error) {
            setMessage('An unexpected error occurred.');
            setMessageType('danger');
        } finally {
            setIsUploading(false);
            setValidated(false);
            // Reset the form
            form.reset();
            // form.classList.remove("was-validated");
        }
    };

    return (
        <main>
            <div className="Intro py-2 py-lg-3">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h1 className="fs-4 text-center">
                                {t(HS_FILE_RE_SUBMISSION)}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <Form noValidate validated={validated} onSubmit={handleUpload} className="bg-lighter py-2 py-lg-3">
                <div className="container">
                    <div className="row py-3">

                        <div className="col-lg-6">
                            <div className="FormComponent mb-3">
                                <UploadFile onFileChange={handleFileChange} onMessageChange={handleMessageChange}
                                            isUploading={isUploading} showButton={false}
                                            ref={fileInputRef}
                                            isValid={fileValid}
                                            mandatoryOnLabel={true}
                                            acceptFileTypes=".csv"
                                            labelMessage={t(HS_LIST_OF_FILES_TO_BE_REPROCESSED)}>

                                </UploadFile>
                                <Row>
                                    {message && (
                                        <Alert variant={messageType} className="mt-3">
                                            {message}
                                        </Alert>
                                    )}
                                </Row>
                            </div>
                        </div>

                        <Col lg={4}>
                            <Form.Group className="FormComponent mb-3" controlId="FRPath">
                                <Form.Label htmlFor="path">{t(HS_PATH_WHERE_THE_FILES_TO_BE_REPROCESSED_ARE_LOCATED)}</Form.Label>
                                <InputGroup>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={renderTooltip(t(RE_SYSTEM_VERSION_TOOLTIP_TEXT))}
                                    >
                                        <Button variant="link" id="button-addon1" className="border-end FormHelp">
                                            <i className="bi bi-info-lg" aria-hidden="true"></i>
                                            <span className="visually-hidden">Help with this field</span>
                                        </Button>
                                    </OverlayTrigger>
                                    <Form.Control
                                        id={'path'}
                                        type="text"
                                        name="path"
                                        placeholder="Enter the path"
                                        // required
                                        // minLength={5} // Enforces a minimum length
                                    />
                                    {/*<Form.Control.Feedback type="invalid">*/}
                                    {/*    Path must be at least 5 characters long.*/}
                                    {/*</Form.Control.Feedback>*/}
                                </InputGroup>
                            </Form.Group>
                        </Col>

                        <div className="col-lg-12 d-flex align-items-center pt-1 justify-content-end">
                            <button type="submit" className="btn btn-primary mb-3"><i
                                className="bi bi-arrow-repeat me-2"></i> {t(HS_REPROCESS_FILES)}
                            </button>
                        </div>

                    </div>
                </div>
            </Form>


        </main>
    );
};

export default HistoryOfSubmission;
