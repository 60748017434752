import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "react-bootstrap";
import { DatasetResponse } from "@/model/InfoviewerResult";
import {
  DataFlowTypes,
  INFOVIEWER_METADATA_CARD_APPROVAL_DATE_FIELD,
  INFOVIEWER_METADATA_CARD_COLLECTION_BODY_FIELD,
  INFOVIEWER_METADATA_CARD_CONSIDERATION_OFFERED_FIELD,
  INFOVIEWER_METADATA_CARD_ENTITY_COUNTRY_FIELD,
  INFOVIEWER_METADATA_CARD_ENTITY_LEI_FIELD,
  INFOVIEWER_METADATA_CARD_ENTITY_NAME_FIELD,
  INFOVIEWER_METADATA_CARD_ENTITY_SECTOR_FIELD,
  INFOVIEWER_METADATA_CARD_ENTITY_SIZE_FIELD,
  INFOVIEWER_METADATA_CARD_EU_GROWTH_PROSPECTUS_FIELD,
  INFOVIEWER_METADATA_CARD_HOME_MEMBER_STATE_FIELD,
  INFOVIEWER_METADATA_CARD_HOST_MEMBER_STATE_FIELD,
  INFOVIEWER_METADATA_CARD_ISIN_FIELD,
  INFOVIEWER_METADATA_CARD_LEGAL_FRAMEWORK_FIELD,
  INFOVIEWER_METADATA_CARD_MORE_METADATA_LABEL,
  INFOVIEWER_METADATA_CARD_PASSPORTED_INDICATOR_FIELD,
  INFOVIEWER_METADATA_CARD_PERSONAL_DATA_FIELD,
  INFOVIEWER_METADATA_CARD_PROSPECTUS_TYPE_FIELD,
  INFOVIEWER_METADATA_CARD_SECURITIES_TYPES_FIELD,
  INFOVIEWER_METADATA_CARD_SUBMISSION_DATE_FIELD,
  INFOVIEWER_METADATA_CARD_TYPE_OF_INFORMATION_FIELD,
  INFOVIEWER_METADATA_CARD_VERSION_FIELD,
  INFOVIEWER_METADATA_TAXONOMY_LG_FRMWRK_KEY_PREFIX_TEXT,
  INFOVIEWER_METADATA_TAXONOMY_REGDATA_CLSFN_KEY_PREFIX_TEXT,
  INFOVIEWER_METADATA_TAXONOMY_SECTOR_KEY_PREFIX_TEXT,
  INFOVIEWER_PASSPORTED_INDICATOR_FALSE_TEXT,
  INFOVIEWER_PASSPORTED_INDICATOR_TRUE_TEXT,
  INFOVIEWER_PRSNL_DATA_NO_TEXT,
  INFOVIEWER_PRSNL_DATA_YES_TEXT,
} from "@/constants/EsapConstants";

export interface MetadataItem {
  key: string;
  value: string;
}

interface MetadataCardProps {
  datasetResponse: DatasetResponse | null;
}

const MetadataCard: React.FC<MetadataCardProps> = ({ datasetResponse }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false); // collapse functionality

  if (!datasetResponse) {
    return null;
  }

  const getCommonMetadata = ({
    datasetResponse,
    taxonomySectorTranslationKey,
    legalFrameworkTranslationKey,
    rgltDataClssctnTraslationKey,
  }: {
    datasetResponse: DatasetResponse;
    taxonomySectorTranslationKey?: string;
    legalFrameworkTranslationKey?: string;
    rgltDataClssctnTraslationKey?: string;
  }): MetadataItem[] => {
    return [
      {
        key: t(INFOVIEWER_METADATA_CARD_COLLECTION_BODY_FIELD),
        value: datasetResponse.metadata.colltnBody || "",
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_ENTITY_LEI_FIELD),
        value: datasetResponse.metadata.rltdNttyLglPrsnLEI || "",
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_ENTITY_NAME_FIELD),
        value: datasetResponse.metadata.rltdNttyLglPrsnOrgNm
          ?.map((orgNmItem) => orgNmItem.val) 
          .filter((val) => val) 
          .join(", ") || "", 
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_ENTITY_COUNTRY_FIELD),
        value: datasetResponse.metadata.rltdNttyLglPrsnRegdCtry || "",
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_ENTITY_SIZE_FIELD),
        value: datasetResponse.metadata.rltdNttyLglPrsnNttySz || "",
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_ENTITY_SECTOR_FIELD),
        value:
          datasetResponse.metadata.rltdNttyLglPrsnSctr
            ?.map((sectorData) => {
              const sector = sectorData.nacetXnmySctr;
              return taxonomySectorTranslationKey && sector
                ? t(`${taxonomySectorTranslationKey}_${sector}`, sector)
                : sector;
            })
            .join(", ") || "",
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_TYPE_OF_INFORMATION_FIELD),
        value:
          datasetResponse.metadata.rgltryDataTp
            ?.map((regulatoryData) => {
              const classification = regulatoryData.clssfctn;
              return rgltDataClssctnTraslationKey && classification
                ? t(`${rgltDataClssctnTraslationKey}_${classification}`, classification)
                : classification;
            })
            .join(", ") || "",
      },
      {
        key:t(INFOVIEWER_METADATA_CARD_LEGAL_FRAMEWORK_FIELD),
        value:
          datasetResponse.metadata.rgltryDataTp
            ?.map((regulatoryType) => {
              const framework = regulatoryType.lglFrmwk;
              return legalFrameworkTranslationKey && framework
                ? t(`${legalFrameworkTranslationKey}_${framework}`, framework)
                : framework;
            })
            .join(", ") || "",
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_SUBMISSION_DATE_FIELD),
        value: datasetResponse.metadata.submissnDtTm || "",
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_HOME_MEMBER_STATE_FIELD),
        value: datasetResponse.metadata.homeCtry || "",
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_HOST_MEMBER_STATE_FIELD),
        value: datasetResponse.metadata.hstCtry?.join(", ") || "",
      },
      { key: t(INFOVIEWER_METADATA_CARD_VERSION_FIELD), value: datasetResponse.metadata.vrsn || "" }
    ];
  };

  const getDATPRIMetadata = (
    datasetResponse: DatasetResponse
  ): MetadataItem[] => {
    const extraFields: MetadataItem[] = [
      {
        key: t(INFOVIEWER_METADATA_CARD_APPROVAL_DATE_FIELD),
        value: `${
          datasetResponse.xmlMetadata.approvalOrFilingDateFrom || ""
        } - ${datasetResponse.xmlMetadata.approvalOrFilingDateTo || ""}`,
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_EU_GROWTH_PROSPECTUS_FIELD),
        value: datasetResponse.xmlMetadata.euGrowthProspectusCategory || "",
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_SECURITIES_TYPES_FIELD),
        value: datasetResponse.xmlMetadata.securitiesType || "",
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_CONSIDERATION_OFFERED_FIELD),
        value: datasetResponse.xmlMetadata.considerationOffered || "",
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_PROSPECTUS_TYPE_FIELD),
        value: datasetResponse.xmlMetadata.prospectusType || "",
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_PASSPORTED_INDICATOR_FIELD),
        value:
          datasetResponse.xmlMetadata.passportedIndicator !== undefined &&
          datasetResponse.xmlMetadata.passportedIndicator !== null
            ? INFOVIEWER_PASSPORTED_INDICATOR_TRUE_TEXT
            : datasetResponse.xmlMetadata.passportedIndicator === null ||
              datasetResponse.xmlMetadata.passportedIndicator === undefined
            ? ""
            : INFOVIEWER_PASSPORTED_INDICATOR_FALSE_TEXT,
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_ISIN_FIELD),
        value: datasetResponse.metadata.rltdIdrIsin || "",
      },
      {
        key: t(INFOVIEWER_METADATA_CARD_PERSONAL_DATA_FIELD),
        value:
          datasetResponse.metadata.prsnlData !== undefined &&
          datasetResponse.metadata.prsnlData !== null
            ? INFOVIEWER_PRSNL_DATA_YES_TEXT
            : datasetResponse.metadata.prsnlData === null ||
              datasetResponse.metadata.prsnlData === undefined
            ? ""
            : INFOVIEWER_PRSNL_DATA_NO_TEXT,
      },
    ];

    const commonMetadata = getCommonMetadata({
      datasetResponse,
      taxonomySectorTranslationKey:
        INFOVIEWER_METADATA_TAXONOMY_SECTOR_KEY_PREFIX_TEXT,
    });

    return [...commonMetadata, ...extraFields];
  };

  const metadataItems: MetadataItem[] = (() => {
    switch (datasetResponse.dataFlowType) {
      case DataFlowTypes.DATPXD:
      case DataFlowTypes.DATTRD:
        return getCommonMetadata({
          datasetResponse,
          taxonomySectorTranslationKey:
            INFOVIEWER_METADATA_TAXONOMY_SECTOR_KEY_PREFIX_TEXT,
          legalFrameworkTranslationKey:
            INFOVIEWER_METADATA_TAXONOMY_LG_FRMWRK_KEY_PREFIX_TEXT,
          rgltDataClssctnTraslationKey:
            INFOVIEWER_METADATA_TAXONOMY_REGDATA_CLSFN_KEY_PREFIX_TEXT,
        });
      case DataFlowTypes.DATPRI:
        return getDATPRIMetadata(datasetResponse);
      default:
        return [];
    }
  })();
  // Divide metadata into two sections
  const firstFourItems = metadataItems.slice(0, 4);
  const additionalItems = metadataItems.slice(4);
  //wrap style
  const wrapText: React.CSSProperties={
    whiteSpace: "normal",
    wordBreak: "break-word",
    overflowWrap: "break-word",
    maxWidth: "100%",
  };

  return (
    <div className="row">
      <div className="col-12">
        <dl className="DList DList-Compact DList-Separators DList-6-6 DL2Cols mt-4">
          {firstFourItems.map((item) => (
            <>
              <dt
                style={wrapText}
              >
                {item.key}:
              </dt>
              <dd
                style={wrapText}
              >
                {item.value}
              </dd>
            </>
          ))}
        </dl>

        {additionalItems.length > 0 && (
          <>
            <p className="text-center">
              <a
                className={`btn btn-link ExpColl ${open ? "" : "collapsed"}`}
                aria-expanded={open}
                onClick={() => setOpen(!open)}
              >
               {t(INFOVIEWER_METADATA_CARD_MORE_METADATA_LABEL)}
              </a>
            </p>

            <Collapse in={open}>
              <div id="AdditionalMetadata">
                <dl className="DList DList-Compact DList-Separators DList-6-6 DL2Cols mt-4">
                  {additionalItems.map((item) => (
                    <>
                      <dt
                      style={wrapText}
                      >
                        {item.key}:
                      </dt>
                      <dd
                        style={wrapText}
                      >
                        {item.value}
                      </dd>
                    </>
                  ))}
                </dl>
              </div>
            </Collapse>
          </>
        )}
      </div>
    </div>
  );
};

export default MetadataCard;
