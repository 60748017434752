import { HistoricalReportResponse, LatestReportResponse, QualityIssuesOfDatasetResponse, SaveQualityIssueResponse } from "@/model/MonitoringResults";
import {monitoringUrl} from "../ServiceEndpoints";
import {fetchWrapper} from "../FetchWrapperService/FetchWrapper.ts";
import { PaginationState } from "@tanstack/react-table";
import { createQualityIssueRowsFromResponse, TableQualityIssueRow } from "@/components/Monitoring/common/ExpandableTable/ExpandableTableData.ts";
import { SuspendResumeResultsResponse } from "@/model/SuspendResumeResults.ts";
import { AvailabilityMonitoringResultsResponse, AvailabilityReportTimestampResponse } from "@/model/AvailabilityMonitoringResultsResponse.ts";

export async function fetchLatestReport(pagination: PaginationState): Promise<LatestReportResponse | null> {
    try {
        const url = `${monitoringUrl}/v1/qualityIssues/latest`;
                const response = await fetchWrapper(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(pagination),
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Fetch latest quality issues failed with status: ${response.status}`);
        }
    } catch (error) {
        console.error("API error:", error);
        return null;
    }
}

export async function fetchQualityIssuesOfDataset(reportId: string, datasetId: string, isLatest: boolean): Promise<QualityIssuesOfDatasetResponse | null> {
    if (!reportId || !datasetId) {
        console.error("Invalid parameters for MonitoringService.fetchQualityIssuesOfDataset method. ReportId: " + reportId + " and datasetId: " + datasetId);
        return null;
    }
    try {
        const url = `${monitoringUrl}/v1/qualityIssues/` + (isLatest ? "" : "historical/") + `${reportId}/${datasetId}`;
        const response = await fetchWrapper(url);
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Fetch quality issues of dataset with id: ${datasetId} for report: ${reportId} failed with status: ${response.status}`);
        }
    } catch (error) {
        console.error("API error:", error);
        return null;
    }
}

export interface SaveQualityIssueRequest {
    reportId: string;
    datasetId: string;
    typeOfIssue: string;
    status?: string;
    priority?: string;
    acknowledgementStatus?: string;
}

export async function saveQualityIssueOfDataset(request: SaveQualityIssueRequest): Promise<SaveQualityIssueResponse | null> {
    if (!request || !request?.reportId || !request?.datasetId || !request?.typeOfIssue) {
        console.error("Invalid parameter for MonitoringService.saveQualityIssueOfDataset method");
        return null;
    }

    try {
        const requestBody = JSON.stringify(request);
        const url = `${monitoringUrl}/v1/qualityIssues/save`;
        const response = await fetchWrapper(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: requestBody,
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Save quality issue of type: ${request.typeOfIssue}, for reportId: ${request.reportId} and datasetId: ${request.datasetId} failed with status: ${response.status}`);
        }
    } catch (error) {
        console.error("API error:", error);
        return null;
    }
}

export async function getQualityIssuesOfDataset(reportId: string | undefined, datasetId: string, isLatest: boolean): Promise<TableQualityIssueRow[]> {
    if (!reportId) {
        console.error("ReportId is null/empty");
        return Promise.resolve([]);
    }
    let errorMsg = "Failed to fetch dataset's quality issues";
    try {
        const response: QualityIssuesOfDatasetResponse | null = await fetchQualityIssuesOfDataset(reportId, datasetId, isLatest);
        if (response) {
            return Promise.resolve(createQualityIssueRowsFromResponse(response, reportId, datasetId));
        }
    } catch (error) {
        if (error instanceof Error) {
            errorMsg = error.message;
        }
        console.error(errorMsg);
    }
    return Promise.resolve([]);
}

export async function fetchLatestHistoricalReport(pagination: PaginationState): Promise<HistoricalReportResponse | null> {
    try {
        const url = `${monitoringUrl}/v1/qualityIssues/historical`;
        const response = await fetchWrapper(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(pagination),
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Fetch latest historical report failed with status: ${response.status}`);
        }
    } catch (error) {
        console.error("API error:", error);
        return null;
    }
}

export async function fetchSpecificHistoricalReport(pagination: PaginationState, reportId: string): Promise<HistoricalReportResponse | null> {
    try {
        const url = `${monitoringUrl}/v1/qualityIssues/historical/${reportId}`;
        const response = await fetchWrapper(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(pagination),
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Fetch specific historical report failed with status: ${response.status}`);
        }
    } catch (error) {
        console.error("API error:", error);
        return null;
    }
}

export async function fetchLatestSuspendResumeReport(pagination: PaginationState): Promise<SuspendResumeResultsResponse | null> {
    try {
        const url = `${monitoringUrl}/v1/suspendResume/results`;
        const response = await fetchWrapper(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(pagination),
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Fetch latest historical report failed with status: ${response.status}`);
        }
    } catch (error) {
        console.error("API error:", error);
        return null;
    }
}

export async function fetchSpecificSuspendResumeReport(pagination: PaginationState, reportId: string): Promise<SuspendResumeResultsResponse | null> {
    try {
        const url = `${monitoringUrl}/v1/suspendResume/results/${reportId}`;
        const response = await fetchWrapper(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(pagination),
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Fetch specific historical report failed with status: ${response.status}`);
        }
    } catch (error) {
        console.error("API error:", error);
        return null;
    }
}

export async function fetchAvailabilityDownloadabilityReport(pagination: PaginationState): Promise<AvailabilityMonitoringResultsResponse | null> {
    try {
        const url = `${monitoringUrl}/v1/availability/results`;
        const response = await fetchWrapper(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(pagination),
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Fetch availability report failed with status: ${response.status}`);
        }
    } catch (error) {
        console.error("API error:", error);
        return null;
    }
}

export async function fetchAvailabilityTimestampReport(): Promise<AvailabilityReportTimestampResponse | null> {
    try {
        const url = `${monitoringUrl}/v1/availability/reportTimestamp`;
    
        const response = await fetchWrapper(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });
    
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Fetch availability report timestamp failed with status: ${response.status}`);
        }
    } catch (error) {
        console.error("API error:", error);
        return null;
    }
    
}


