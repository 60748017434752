import {LS_USER_UUID} from "@/constants/EsapConstants.ts";
import {uuidGenerator} from "@/utils/uuidGenerator.ts";

class LocalStorageUUIDService {

    setUserUuid = () => {
        if(!localStorage.getItem(LS_USER_UUID)) {
            localStorage.setItem(LS_USER_UUID, uuidGenerator());
        }
    }

    getUserUuid: () => string | null = () => {
        return localStorage.getItem(LS_USER_UUID);
    }

    removeUserUuid = () => () => {
        localStorage.removeItem(LS_USER_UUID);
    }

}

const localStorageUUIDService = new LocalStorageUUIDService();
export default localStorageUUIDService;