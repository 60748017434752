import { useState, useEffect, useCallback } from "react";
import {
  AvailabilityDownloadabilityTableRow,
  createTableRowsFromAvailabilityResultsResponse,
} from "./common/AvailabilityTable/AvailabilityDownloadabilityTableData";
import { AvailabilityDownloadabilityTable } from "./common/AvailabilityTable/AvailabilityDownloadabilityTable";
import { PaginationState } from "@tanstack/react-table";
import { useUserInfo } from "@/hooks/useUserInfo";
import { UserInfo } from "../../model/UserInfo.ts";
import {
  AvailabilityMonitoringResultsResponse,
  AvailabilityReportTimestampResponse,
} from "@/model/AvailabilityMonitoringResultsResponse.ts";
import {
  fetchAvailabilityDownloadabilityReport,
  fetchAvailabilityTimestampReport,
} from "@/services/MonitoringService/MonitoringService.ts";
import { t } from "i18next";
import {
  MONITORING_AVAILABILITY_DATE_OF_EXECUTION_MESSAGE,
  MONITORING_AVAILABILITY_FETCH_RESULTS_FAILED_MESSAGE,
  MONITORING_AVAILABILITY_FETCH_TIMESTAMP_FAILED_MESSAGE,
  MONITORING_AVAILABILITY_MONITORING_LABEL,
  MONITORING_AVAILABILITY_TABLE_PAGINATION_TEXT_1,
  MONITORING_AVAILABILITY_TABLE_PAGINATION_TEXT_2,
  MONITORING_AVAILABILITY_TABLE_PAGINATION_TEXT_3,
  Roles,
} from "@/constants/EsapConstants.ts";
import { Spinner } from "react-bootstrap";
import { formatDate, formatTime } from "./common/DateAndTimeFormat.ts";

const AvailabilityMonitoring: React.FC = () => {
  const [availabilityReportResponse, setAvailabilityReportResponse] =
    useState<AvailabilityMonitoringResultsResponse | null>(null);
  const [tableData, setTableData] = useState<
    AvailabilityDownloadabilityTableRow[]
  >([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [error, setError] = useState<string | null>(null);
  const [timestampError, setTimestampError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  // User Info
  const { user } = useUserInfo();
  const [userInfo, setUserInfo] = useState<UserInfo | null>();
  //Formated date time
  const [formattedDateOfReport, setFormattedDateOfReport] =
    useState<string>("");
  const [formattedTimeOfReport, setFormattedTimeOfReport] =
    useState<string>("");
  useEffect(() => {
    setUserInfo(user);
  }, [user, setUserInfo]);

  const hasRoles = useCallback(
    (roles: string[]) => {
      return userInfo?.roles.some((r) => roles.includes(r));
    },
    [userInfo]
  );

  useEffect(() => {
    const getAvailabilityReportTimestamp = async () => {
      let errorMsg = t(MONITORING_AVAILABILITY_FETCH_TIMESTAMP_FAILED_MESSAGE);
      try {
        const response: AvailabilityReportTimestampResponse | null =
          await fetchAvailabilityTimestampReport();
        setLoading(false);
        if (!response) {
          setTimestampError(errorMsg);
        } else {
          const dateOfReport = new Date(response.availabilityReportTimestamp);
          setFormattedDateOfReport(formatDate(dateOfReport));
          setFormattedTimeOfReport(formatTime(dateOfReport));
        }
      } catch (error) {
        if (error instanceof Error) {
          errorMsg = error.message;
        }
        setTimestampError(errorMsg);
        console.error(errorMsg);
      }
    };

    if (hasRoles([Roles.ESAP_DATA_MANAGER_ROLE])) {
      setLoading(true);
      getAvailabilityReportTimestamp();
    }
  }, [hasRoles]);

  useEffect(() => {
    const getAvailabilityReport = async () => {
      let errorMsg = t(MONITORING_AVAILABILITY_FETCH_RESULTS_FAILED_MESSAGE);
      try {
        const response: AvailabilityMonitoringResultsResponse | null =
          await fetchAvailabilityDownloadabilityReport(pagination);
        setLoading(false);
        if (!response) {
          setError(errorMsg);
        } else {
          setAvailabilityReportResponse(response);
          setTableData(
            createTableRowsFromAvailabilityResultsResponse(response)
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          errorMsg = error.message;
        }
        setError(errorMsg);
        console.error(errorMsg);
      }
    };

    if (hasRoles([Roles.ESAP_DATA_MANAGER_ROLE])) {
      setLoading(true);
      getAvailabilityReport();
    }
  }, [hasRoles, pagination]);

  //pagination
  function handlePageChange(action: string) {
    let pageIndex = pagination.pageIndex;
    const totalPages = Math.ceil(
      (availabilityReportResponse?.totalResults
        ? availabilityReportResponse?.totalResults
        : 0) / pagination.pageSize
    );

    switch (action) {
      case "next":
        if (pagination.pageIndex < totalPages) {
          pageIndex += 1;
        }
        break;
      case "previous":
        if (pagination.pageIndex > 1) {
          pageIndex -= 1;
        }
        break;
      case "first":
        pageIndex = 1;
        break;
      case "last":
        pageIndex = totalPages;
        break;
      default:
        return;
    }

    if (pageIndex != pagination.pageIndex) {
      setPagination({ pageIndex: pageIndex, pageSize: pagination.pageSize });
    }
  }

  function handlePageSizeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const newPageSize = parseInt(event.target.value, 10);
    if (newPageSize != pagination.pageSize) {
      setPagination({ pageIndex: 1, pageSize: newPageSize });
    }
  }

  return (
    <main>
      <div className="Intro py-2 py-lg-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="fs-4 text-center">
                {t(MONITORING_AVAILABILITY_MONITORING_LABEL)}
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <form className="bg-lighter py-2 py-lg-3">
        <div className="container">
          <div className="row py-3">
            <div className="col-lg-4">
              <div className="FormComponent mb-3">
                <label htmlFor="AMAvailStatus" className="form-label">
                  Availability Status:
                </label>
                <div className="input-group">
                  <button
                    id="AMAvailStatusHelp"
                    className="btn btn-sm border-end FormHelp"
                    type="button"
                    data-bs-toggle="tooltip"
                    data-bs-title="More information about this field"
                  >
                    <i className="bi bi-info-lg" aria-hidden="true"></i>
                    <span className="visually-hidden">
                      Help with this field
                    </span>
                  </button>
                  <select
                    aria-describedby="AMAvailStatusHelp"
                    id="AMAvailStatus"
                    className="form-select"
                  >
                    <option></option>
                    <option>Available</option>
                    <option>Not Available</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="FormComponent mb-3">
                <label htmlFor="AMDLStatus" className="form-label">
                  Downloadability Status:
                </label>
                <div className="input-group">
                  <button
                    id="AMDLStatusHelp"
                    className="btn btn-sm border-end FormHelp"
                    type="button"
                    data-bs-toggle="tooltip"
                    data-bs-title="More information about this field"
                  >
                    <i className="bi bi-info-lg" aria-hidden="true"></i>
                    <span className="visually-hidden">
                      Help with this field
                    </span>
                  </button>
                  <select
                    aria-describedby="AMDLStatusHelp"
                    id="AMDLStatus"
                    className="form-select"
                  >
                    <option></option>
                    <option>Downloadable</option>
                    <option>Not Downloadable</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <fieldset>
                <legend>Date and Time of execution:</legend>
                <div className="input-group">
                  <button
                    id="AMDateHelp"
                    className="btn btn-sm border-end FormHelp"
                    type="button"
                    data-bs-toggle="tooltip"
                    data-bs-title="More information about this field"
                  >
                    <i className="bi bi-info-lg" aria-hidden="true"></i>
                    <span className="visually-hidden">
                      Help with this field
                    </span>
                  </button>
                  <input
                    aria-describedby="AMDateHelp"
                    type="text"
                    className="form-control"
                    placeholder="From"
                    aria-label="From"
                  />
                  <button
                    className="btn btn-sm btn-link link-dark bg-white border border-start-0"
                    type="button"
                  >
                    <i className="bi bi-calendar4" aria-hidden="true"></i>
                    <span className="visually-hidden">Date picker</span>
                  </button>
                  <input
                    aria-describedby="AMDateHelp"
                    type="text"
                    className="form-control"
                    placeholder="To"
                    aria-label="To"
                  />
                  <button
                    className="btn btn-sm btn-link link-dark bg-white border border-start-0"
                    type="button"
                  >
                    <i className="bi bi-calendar4" aria-hidden="true"></i>
                    <span className="visually-hidden">Date picker</span>
                  </button>
                </div>
              </fieldset>
            </div>

            <div className="col-12 d-flex justify-content-end pt-3 mt-3 border-top">
              <button type="submit" className="btn btn-primary">
                <i className="bi bi-filter me-2"></i> Apply filters
              </button>
              <button type="reset" className="btn btn-link link-dark ms-3">
                <i className="bi bi-arrow-clockwise me-2"></i> Reset filters
              </button>
            </div>
          </div>
        </div>
      </form> */}

      <div className="py-5">
        <div className="container">
          {loading && (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}

          {!loading && error && (
            <div className="text-center text-danger">
              <h4>{error}</h4>
            </div>
          )}
          {!loading && !error && availabilityReportResponse && (
            <div className="row py-3">
              <div className="col">
                {formattedDateOfReport && formattedTimeOfReport ? (
                  <h2 className="mb-3 fs-5">
                    <span className="fw-normal">
                      {t(MONITORING_AVAILABILITY_DATE_OF_EXECUTION_MESSAGE)}
                    </span>{" "}
                    {formattedDateOfReport}{" "}
                    <small className="text-secondary">
                      {formattedTimeOfReport}
                    </small>
                  </h2>
                ) : (
                  <div className="text-center text-danger">
                    <h4>{timestampError}</h4>
                  </div>
                )}
                <AvailabilityDownloadabilityTable data={tableData} />
                {/* PAGINATION */}
                <div className="d-flex align-items-center justify-content-end gap-2 pt-2">
                  <button
                    className="btn border rounded p-1"
                    onClick={() => handlePageChange("first")}
                    disabled={pagination.pageIndex === 1}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="btn border rounded p-1"
                    onClick={() => handlePageChange("previous")}
                    disabled={pagination.pageIndex === 1}
                  >
                    {"<"}
                  </button>

                  <button
                    className="btn border rounded p-1"
                    onClick={() => handlePageChange("next")}
                    disabled={
                      pagination.pageIndex * pagination.pageSize >=
                      availabilityReportResponse.totalResults
                    }
                  >
                    {">"}
                  </button>

                  <button
                    className="btn border rounded p-1"
                    onClick={() => handlePageChange("last")}
                    disabled={
                      pagination.pageIndex * pagination.pageSize >=
                      availabilityReportResponse.totalResults
                    }
                  >
                    {">>"}
                  </button>

                  <span className="d-flex align-items-center gap-1">
                    <div>
                      {t(MONITORING_AVAILABILITY_TABLE_PAGINATION_TEXT_1)}
                    </div>
                    {pagination.pageIndex}{" "}
                    {t(MONITORING_AVAILABILITY_TABLE_PAGINATION_TEXT_2)}{" "}
                    {Math.floor(
                      availabilityReportResponse.totalResults /
                        pagination.pageSize
                    ) + 1}
                  </span>

                  <select
                    style={{ width: "auto" }}
                    className="form-select form-select-sm"
                    value={pagination.pageSize}
                    onChange={handlePageSizeChange}
                  >
                    {[10, 20, 40].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {t(MONITORING_AVAILABILITY_TABLE_PAGINATION_TEXT_3)}{" "}
                        {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default AvailabilityMonitoring;
