
import { formatDate } from "../DateAndTimeFormat";
import { AvailabilityMonitoringResultsResponse } from "@/model/AvailabilityMonitoringResultsResponse";

export type AvailabilityDownloadabilityTableRow = {
    datasetId: string;
    availabilityStatus: string;
    downloadabilityStatus : string;
    timestamp: string;
}

export const createTableRowsFromAvailabilityResultsResponse = (response: AvailabilityMonitoringResultsResponse): AvailabilityDownloadabilityTableRow[] => {
    const tableRows: AvailabilityDownloadabilityTableRow[] = [];
    if (response && response.results.length) {
        console.log("HERE");
        response.results.forEach((result) => {
            const row: AvailabilityDownloadabilityTableRow = {
                datasetId: result.datasetId,
                availabilityStatus: result. availabilityStatus,
                downloadabilityStatus: result.downloadabilityStatus,
                timestamp: result.timestamp ? formatDate(new Date(result.timestamp)) : ""
            };
            tableRows.push(row);
        });
    }
    return tableRows;
}