import {TranslationProvider} from "./TranslationProvider.tsx";
import {UserInfoProvider} from "./UserInfoProvider.tsx";
import {AuthProvider} from "react-oidc-context";
import oidcConfig from "../config/oidcConfig.ts";
import { useEffect } from "react";
import { useAppInsights } from "@/hooks/useAppInsights.ts";
import { uuidGeneratorWithTimestamp } from "@/utils/uuidGenerator.ts";
import { CustomEvents } from "@/constants/EsapConstants.ts";

export const EsapProvider = ({ children }: { children: React.ReactNode }) => {
    const appInsights = useAppInsights();

    useEffect(() => {
        const createVisitorEvent = () => {
            const eventUUID = uuidGeneratorWithTimestamp();
            appInsights.trackEvent(
              { name: CustomEvents.VISITOR },
              { eventUUID: eventUUID }
            );
        };

        const currentDate: string = new Date().toJSON().slice(0, 10);
        if (localStorage.getItem('dateLastVisit') != currentDate) {
            localStorage.removeItem('dateLastVisit');
            localStorage.setItem('dateLastVisit', `${currentDate}`);
            createVisitorEvent();
        }
    }, [appInsights]);

    return (
        <AuthProvider {...oidcConfig}>
            <TranslationProvider>
                <UserInfoProvider>
                    {children}
                </UserInfoProvider>
            </TranslationProvider>
        </AuthProvider>
    );
};