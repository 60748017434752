import { t } from "i18next"
import { Container, Row, Col } from "react-bootstrap"

const MonitoringHeader = (props: {title: string}) => {
    return (
        <>
        <div className="Intro py-2 py-lg-3">
            <Container>
                <Row>
                    <Col>
                        <h1 className="fs-4 text-center">
                            {t(props.title)}
                        </h1>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}

export default MonitoringHeader;