import React from "react";

interface CsvDisplayProps {
  rows: string[][];
}

const CsvDisplay: React.FC<CsvDisplayProps> = ({ rows }) => {

  return (
    <div className="d-flex justify-content-center align-items-center">
        <div>
          <div className="bg-white border text-center rounded-1 mt-3">
            <table className="table table-sm table-striped-columns table-hover m-0">
              <tbody>
                {rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
    </div>
  );
};

export default CsvDisplay;
