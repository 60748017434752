import { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { downloadDataset, downloadDatasetFile, fetchDatasetFileset, fetchFileContents, calculateIfNeedsProcessing, fetchCsvFile,
} from "../../services/DatasetDetailsService/DatasetDetailsService";
import { CUSTOM_EVENTS_PREFIXES, DOWNLOAD_CART_ADD_TO_CART_MESSAGE, DOWNLOAD_CART_REMOVE_FROM_CART_MESSAGE, FileTypes, INFOVIEWER_DATASET_DETAILS_ADD_TO_CART_BUTTON, INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_FIRST_BUTTON, INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_LAST_BUTTON, INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_NEXT_BUTTON, INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_PREVIOUS_BUTTON, INFOVIEWER_DATASET_DETAILS_DOWNLOAD_BUTTON, INFOVIEWER_DATASET_DETAILS_DOWNLOAD_DATASET_ERROR_MESSAGE,INFOVIEWER_DATASET_DETAILS_DOWNLOADING_SPINNER, INFOVIEWER_DATASET_DETAILS_FETCH_DATASET_ERROR_MESSAGE, INFOVIEWER_DATASET_DETAILS_FETCH_FILE_ERROR_MESSAGE, INFOVIEWER_DATASET_DETAILS_IN_CART_BUTTON, INFOVIEWER_DATASET_DETAILS_SELECT_DOCUMENT, INFOVIEWER_DATASET_DETAILS_SELECT_FILE_MESSAGE, INFOVIEWER_DATASET_DETAILS_TRANSLATE_BUTTON, INFOVIEWER_DATASET_DETAILS_TRANSLATE_SPINNER, INFOVIEWER_DATASET_DETAILS_VIEW_BUTTON, SEARCH_RESULTS_ADD_DATA_LIMIT_REACHED } from "../../constants/EsapConstants";
import { DatasetResponse, File } from "../../model/InfoviewerResult";
import XmlDisplay from "./components/XmlDisplay";
import XbrlDisplay from "./components/XbrlDisplay";
import PlainTextDisplay from "./components/PlainTextDisplay";
import PdfDisplay from "./components/PdfDisplay";
import JsonDisplay from "./components/JsonDisplay";
import HtmlDisplay from "./components/HtmlDisplay";
import EsefZipDisplay from "./components/EsefZipDisplay";
import CsvDisplay from "./components/CsvDisplay";
import { Spinner} from "react-bootstrap";
import useCart from "../../services/CartSevice/CartService";
import { CartItem } from "../../model/CartItem.ts";
import { uuidGeneratorWithTimestamp } from "../../utils/uuidGenerator";
import { useAppInsights } from "../../hooks/useAppInsights";
import { CustomEvents, DataFlowTypes, Roles,
 } from "../../constants/EsapConstants";
import { useTranslation } from "react-i18next";
import { CartCountContext } from "../../context/CartCountContext.tsx";
import DATSSRMetadata from "./metadataDetailsComponents/DATSSRMetadata.tsx";
import DATSSRView from "./dataflowsViews/DATSSRView.tsx";
import Toast from "../Common/Toast.tsx";
import TranslateModal from "./components/TranslateModal.tsx";
import {
    sendFileForTranslation,
} from "../../services/TranslationService/DocumentTranslationService.ts";
import { useUserInfo } from "../../hooks/useUserInfo.ts";
import { UserInfo } from "../../model/UserInfo.ts";
import DrupalIcons from "./drupalIcons/DrupalIcons.tsx";
import { getCartLimit } from "../../services/CartSevice/BulkDownloadService.ts";
import {EventUUIDContext} from "../../context/EventUUIDContext.tsx";
import MetadataCard from "./metadataDetailsComponents/MetadataCard.tsx";

const DatasetDetails: React.FC = () => {
  const { datasetId } = useParams();
  const [datasetResponse, setDatasetResponse] =
    useState<DatasetResponse | null>(null);
  const [datasetError, setDatasetError] = useState<string | null>(null);
  const { addCartItem, deleteCartItem, getCartItems } = useCart();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const [isShowingTranslatedFile, setIsShowingTranslatedFile] = useState<boolean>(false);
  const [translatedFileUrl, setTranslatedFileUrl] = useState<string | null>(null);
  const [fileContent, setFileContent] = useState<
    string | Blob | object | object[] | null
  >(null);
  const [contentError, setContentError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<string | null>(null);
  const [showDownloadErrorToast, setShowDownloadErrorToast] = useState(false);
  const [isInCart, setIsInCart] = useState<boolean>(false);
  const [cartLimit, setCartLimit] = useState<number>(5);
  const appInsights = useAppInsights();
  const { t } = useTranslation();
  const { count, setCount } = useContext(CartCountContext)!;
  const { eventUUID: searchEventUUID } = useContext(EventUUIDContext) || {
    eventUUID: null,
  };
  const [showAddToCartToast, setShowAddToCartToast] = useState(false);
  const [showRemoveFromCartToast, setShowRemoveFromCartToast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isFileTranslating, setIsFileTranslating] = useState(false);
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string>("");
  // state for modal spinner
  const [fileSentForTranslation, setFileSentForTranslation] = useState(false);

 //csv states
 const [csvRows, setCsvRows] = useState<string[][]>([]);
 const [currentCsvPage, setCurrentCsvPage] = useState<number>(0);
 const [totalCsvPages, setTotalCsvPages] = useState<number>(0);
 const itemsPerCsvPage = 10;

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => {
    setFileSentForTranslation(false);
    setFrom(null);
    setTo("");
    setShowModal(false);
  };

  const { user } = useUserInfo();
  const [userInfo, setUserInfo] = useState<UserInfo | null>();

  useEffect(() => {
    setUserInfo(user);
  }, [user, setUserInfo]);

  const hasRoles = useCallback(
    (roles: string[]) => {
      return userInfo?.roles.some((r) => roles.includes(r));
    },
    [userInfo]
  );

  useEffect(() => {
    const fetchCartLimit = async () => {
      try {
        if (hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
          const { cartLimit } = await getCartLimit();
          setCartLimit(cartLimit);
        }
      } catch (error) {
        console.error("Failed to fetch cart limit:", error);
      }
    };

    fetchCartLimit();
  }, [hasRoles]);

  useEffect(() => {
    const getDatasetFileset = async (datasetIdParam: string) => {
      if (datasetIdParam && hasRoles([Roles.INFORMATION_VIEWER_ROLE])) {
        const startTime = Date.now();
        const errorMessage =t(INFOVIEWER_DATASET_DETAILS_FETCH_DATASET_ERROR_MESSAGE);
        try {
          const result: DatasetResponse | null = await fetchDatasetFileset(
            datasetIdParam
          );
          setDatasetResponse(result);
          setDatasetError(result != null ? null : errorMessage);
          if (result) {
            const endTime = Date.now();
            createCustomDatasetEvent(
              CustomEvents.VIEW_DATASET,
              endTime - startTime,
              result.datasetId,
              result.dataFlowType
            );          
          }
          setSelectedFile(null);
          const isDatasetInCart = getCartItems().some(
            (cartItem) => cartItem.datasetId === datasetIdParam
          );
          setIsInCart(isDatasetInCart);
        } catch (err) {
          setDatasetError(errorMessage);
          console.error(err);
        }
      }
    };

    if (datasetId) {
      // Fetch dataset fileset along with metadata.
      getDatasetFileset(datasetId);
    } else {
      console.error("DatasetId was null/undefined");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetId, hasRoles]);

  //Translation
  const handleTranslationClick = async (_from: string | null, to: string) => {
    if (!datasetResponse?.datasetId || !selectedFile) {
      console.error("DatasetId or fileId is empty/null");
      return;
    }
    setFileSentForTranslation(true);
    await sendFileForTranslation(
      _from,
      to,
      datasetResponse.datasetId,
      selectedFile,
      setFileContent,
      setIsFileTranslating,
      setIsShowingTranslatedFile,
      setTranslatedFileUrl,
      handleModalClose
    );
  };

  //Cart
  const handleCartToggle = (datasetResponse: DatasetResponse) => {
    if (!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
      return;
    }
    const isInCart = getCartItems().some(
      (cartItem) => cartItem.datasetId === datasetResponse.datasetId
    );

    if (isInCart) {
      deleteCartItem(datasetResponse.datasetId);
      setIsInCart(false);
      setCount(count > 0 ? count - 1 : 0);
       //remove cart toast
       setShowRemoveFromCartToast(true);
       setShowAddToCartToast(false);
       setTimeout(() => {
        setShowRemoveFromCartToast(false);
       }, 2000);
    } else {
      const newCartItem: CartItem = {
        datasetId: datasetResponse.datasetId,
        rltdPrdFrDt: datasetResponse.metadata.rltdPrdFrDt,
        rltdPrdToDt: datasetResponse.metadata.rltdPrdToDt,
        rltdNttyLglPrsnLEI: datasetResponse.metadata.rltdNttyLglPrsnLEI,
        rltdNttyLglPrsnOrgMainNm:
          datasetResponse.metadata.rltdNttyLglPrsnOrgMainNm,
        rgltryDataTp: datasetResponse.metadata.rgltryDataTp.map((data) => ({
          clssfctn: data.clssfctn,
          lglFrmwk: data.lglFrmwk,
        })),
      };
      addCartItem(newCartItem);
      setIsInCart(true);
      setCount(count + 1);
      //add to cart toast
      setShowRemoveFromCartToast(false);
      setShowAddToCartToast(true);
      setTimeout(() => {
        setShowAddToCartToast(false);
      }, 2000);
    }
  };

  //Events
  const createCustomFileEvent = (name: string, dataFormat: string) => {
    const eventUUID = uuidGeneratorWithTimestamp();
    appInsights.trackEvent(
      { name: name },
      { eventUUID: eventUUID, dataFormat: dataFormat }
    );
  };

  const createCustomDatasetEvent = (
    name: string,
    responseTime: number,
    datasetId: string,
    dataFlowType: string
  ) => {
    const eventUUID = uuidGeneratorWithTimestamp();

    const typeOfInformationEntries = Object.entries({});
    if (datasetResponse?.metadata?.rgltryDataTp) {
      datasetResponse?.metadata?.rgltryDataTp.forEach((rgltryDataTp) => {
        typeOfInformationEntries.push([
          CUSTOM_EVENTS_PREFIXES.TYPE_OF_INFORMATION.concat(
            rgltryDataTp.clssfctn
          ),
          true,
        ]);
      });
    }
    const typeOfInformationObject = Object.fromEntries(
      typeOfInformationEntries
    );

    const legalFrameworkEntries = Object.entries({});
    if (datasetResponse?.metadata?.rgltryDataTp) {
      datasetResponse?.metadata?.rgltryDataTp.forEach((rgltryDataTp) => {
        legalFrameworkEntries.push([
          CUSTOM_EVENTS_PREFIXES.LEGAL_FRAMEWORK.concat(rgltryDataTp.lglFrmwk),
          true,
        ]);
      });
    }
    const legalFrameworkObject = Object.fromEntries(legalFrameworkEntries);

    appInsights.trackEvent(
      { name: name },
      {
        eventUUID: eventUUID,
        responseTime: responseTime / 1000,
        datasetId: datasetId,
        fileTypeCode: dataFlowType,
        searchEventUUID: searchEventUUID,
        ...typeOfInformationObject,
        ...legalFrameworkObject,
      }
    );
  };

  //Downloads
  async function handleDownload(datasetId: string, dataFlowType: string, colltnBody: string, techRcrdIdr: string) {
    if (!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
      return;
    }
    const startTime = Date.now();
    if (datasetId) {
      setIsDownloading(true);
      setDownloadError(null);
      try {
        if (await downloadDataset(datasetId, dataFlowType, colltnBody, techRcrdIdr)) {
          const endTime = Date.now();
          createCustomDatasetEvent(
            CustomEvents.DOWNLOAD_DATASET,
            endTime - startTime,
            datasetId,
            dataFlowType
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          setDownloadError(error.message);
        } else {
          setDownloadError(t(INFOVIEWER_DATASET_DETAILS_DOWNLOAD_DATASET_ERROR_MESSAGE));
        }
        setShowDownloadErrorToast(true);
        setTimeout(() => {
          setShowDownloadErrorToast(false);
        }, 2000);
      } finally {
        setIsDownloading(false);
      }
    } else {
      console.error("datasetId was empty/null");
    }
  }

  async function handleDatasetFileDownload(
    datasetId: string,
    file: File | null
  ) {
    if (!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
      return;
    }
    setIsFileDownloading(true);
    setDownloadError(null);
    try {
      if (await downloadDatasetFile(datasetId, file!.fileId, file!.fileName, isShowingTranslatedFile ? translatedFileUrl : null)) {
        createCustomFileEvent(CustomEvents.DOWNLOAD_FILE, file!.fileType);
      }
    } catch (error) {
      if (error instanceof Error) {
        setDownloadError(error.message);
      } else {
        setDownloadError("An error occurred while downloading the file.");
      }
      setShowDownloadErrorToast(true);
      setTimeout(() => {
        setShowDownloadErrorToast(false);
      }, 2000);
    } finally {
      setIsFileDownloading(false);
    }
  }

  //Date Format for Title
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  //File Selection For View

  //event from selection bar
  const handleSelectFileChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedFileId(event.target.value);
  };

  //View button
  const handleViewClick = () => {
    if (selectedFileId) {
      const file = datasetResponse?.files?.find(
        (file) => file.fileId === selectedFileId
      );

      if (file) {
        setIsShowingTranslatedFile(false);
        setTranslatedFileUrl(null);
        if (file.fileType !== FileTypes.CSV) {
          handleFileView(file);
        } else {
          setSelectedFile(file);
          setContentError(null);
          handleCsvPageView(selectedFileId, 0);
        }
      }
    }
  }


  //View  all files but csv
  async function handleFileView(file: File) {
    if (!hasRoles([Roles.INFORMATION_VIEWER_ROLE])) {
      return;
    }

    setSelectedFile(file);
    setContentError(null);
    setLoading(true);

    if (!(datasetResponse?.datasetId && datasetResponse?.dataFlowType)) {
      console.log(datasetResponse)
      setFileContent(null);
      setLoading(false);
      return;
    }

    try {
      const needProcessing: boolean = calculateIfNeedsProcessing(
        datasetResponse,
        file
      );
      const content = await fetchFileContents(
        datasetResponse.datasetId,
        file.fileId,
        file.fileType,
        needProcessing
      );
      if (content) {
        createCustomFileEvent(CustomEvents.VIEW_FILE, file.fileType);
        setFileContent(content);
        setContentError(null);
      }
    } catch (err) {
      if (err instanceof Error) {
        setContentError(err.message);
      } else {
        setContentError(t(INFOVIEWER_DATASET_DETAILS_FETCH_FILE_ERROR_MESSAGE));
      }
      setFileContent(null);
    } finally {
      setLoading(false);
    }
  }

  //csv functionality
  async function handleCsvPageView (fileId: string,pageNumber: number) {
    if (!hasRoles([Roles.INFORMATION_VIEWER_ROLE])) {
      return;
    }
    setLoading(true);
    if (!(datasetResponse?.datasetId)) {
      setFileContent(null);
      setLoading(false);
      return;
    } 
    try {
      const content = await fetchCsvFile(datasetResponse?.datasetId, fileId, pageNumber);
      if (content && Array.isArray(content.pageData) && content.pageData.length > 0) {
        createCustomFileEvent(CustomEvents.VIEW_FILE, FileTypes.CSV);
        setCsvRows(content.pageData);
        setTotalCsvPages(Math.max(0, Math.ceil(content.totalRows / itemsPerCsvPage) - 1));
        setCurrentCsvPage(pageNumber);
      } else {
        throw new Error(`Invalid or empty CSV content for page ${pageNumber}`);
      }
    } catch (error) {
      setContentError(t(INFOVIEWER_DATASET_DETAILS_FETCH_FILE_ERROR_MESSAGE));
    } finally {
      setLoading(false);
    }
  }
  
  const handlePagination = (action: string) => {
    let page = currentCsvPage;
    switch (action) {
      case "first":
        page = 0;
        break;
      case "prev":
        page = Math.max(currentCsvPage - 1, 0);
        break;
      case "next":
        page = Math.min(currentCsvPage + 1, totalCsvPages);
        break;
      case "last":
        page = totalCsvPages;
        break;
      default:
        break;
    }
    if (page !== currentCsvPage && selectedFileId) {
      handleCsvPageView(selectedFileId, page); 
    }
    
  };

  if (datasetError) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "30px",
          fontWeight: "bold",
          color: "red",
          textAlign: "center",
        }}
      >
        {datasetError}
      </div>
    );
  }
  if (!datasetResponse) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner />
      </div>
    );
  }
  
  return (
    <main className="bg-white TopDivider">
      <div className="DHeader  mt-5 pt-5 pb-4 mx-3 rounded-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="IVTitle d-lg-flex pb-2">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="tooltip-top">
                      {datasetResponse.metadata.rgltryDataTp
                        .map((regulatoryData) =>
                          t(
                            `INFOVIEWER_METADATA_NACE_TAXONOMY_SECTOR_${regulatoryData.clssfctn}`,
                            regulatoryData.clssfctn
                          )
                        )
                        .join(", ")}
                    </Tooltip>
                  }
                >
                  <span className="IVT IVTa">
                    {t(
                      `INFOVIEWER_METADATA_NACE_TAXONOMY_SECTOR_${datasetResponse.metadata.rgltryDataTp[0].clssfctn}`,
                      datasetResponse.metadata.rgltryDataTp[0].clssfctn
                    ) || ""}
                  </span>
                </OverlayTrigger>

                <span className="IVT IVTb">
                  {datasetResponse.metadata.rltdNttyLglPrsnOrgMainNm}
                </span>
                <span className="IVT IVTc">
                  {datasetResponse.metadata.rltdNttyLglPrsnLEI}
                </span>
                <span className="IVT IVTd">
                  {datasetResponse.metadata.rltdPrdToDt
                    ? `${formatDate(
                        datasetResponse.metadata.rltdPrdFrDt
                      )} - ${formatDate(datasetResponse.metadata.rltdPrdToDt)}`
                    : `${formatDate(datasetResponse.metadata.rltdPrdFrDt)}`}
                </span>
              </h1>
            </div>
          </div>

          {/*Download dataset and Add to Cart buttons */}
          <div className="row">
            <div className="col-12">
              <div className="p-0 d-flex flex-column flex-md-row justify-content-between rounded-1 DHInfo">
                <div className="d-flex justify-content-start">
                  <button
                    type="button"
                    disabled={
                      isDownloading || !hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])
                    }
                    className="btn btn-outline-primary border-0 rounded-0 px-2 px-lg-3 d-flex align-items-center"
                    onClick={() =>
                      handleDownload(
                        datasetResponse?.datasetId,
                        datasetResponse?.dataFlowType,
                        datasetResponse?.metadata?.colltnBody,
                        datasetResponse?.metadata?.techRcrdIdr
                      )
                    }
                  >
                    {isDownloading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        {t(INFOVIEWER_DATASET_DETAILS_DOWNLOADING_SPINNER)}
                      </>
                    ) : (
                      <>
                        <i
                          className="bi bi-download me-2"
                          aria-hidden="true"
                        ></i>
                        {t(INFOVIEWER_DATASET_DETAILS_DOWNLOAD_BUTTON)}
                      </>
                    )}
                  </button>
                  {datasetResponse.dataFlowType === DataFlowTypes.DATSSR ? (
                    <button
                      type="button"
                      className="btn btn-outline-primary border-0 rounded-0 px-2 px-lg-3"
                      data-bs-toggle="modal"
                      data-bs-target="#ModalTranslate"
                    >
                      <i
                        className="bi bi-translate me-2"
                        aria-hidden="true"
                      ></i>{" "}
                      {t(INFOVIEWER_DATASET_DETAILS_TRANSLATE_BUTTON)}
                    </button>
                  ) : (
                    <></>
                  )}

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      !isInCart && getCartItems().length >= cartLimit ? (
                        <Tooltip id="tooltip-limit-reached">
                          {t(SEARCH_RESULTS_ADD_DATA_LIMIT_REACHED)}
                        </Tooltip>
                      ) : (
                        <></>
                      )
                    }
                  >
                    <span
                      style={{ display: "inline-flex", verticalAlign: "top" }}
                    >
                      <button
                        type="button"
                        className="btn btn-outline-primary border-0 rounded-0 px-2 px-lg-3"
                        onClick={() => {
                          handleCartToggle(datasetResponse);
                        }}
                        disabled={
                          isDownloading ||
                          !hasRoles([Roles.DOWNLOAD_SERVICE_ROLE]) ||
                          (!isInCart && getCartItems().length >= cartLimit)
                        }
                      >
                        <i
                          className={
                            isInCart ? "bi bi-bag-check-fill" : "bi bi-bag-plus"
                          }
                          aria-hidden="true"
                        ></i>
                        {isInCart
                          ? t(INFOVIEWER_DATASET_DETAILS_IN_CART_BUTTON)
                          : t(INFOVIEWER_DATASET_DETAILS_ADD_TO_CART_BUTTON)}
                      </button>
                    </span>
                  </OverlayTrigger>
                </div>
                {/*Drupal Icons*/}
                <DrupalIcons
                  eSealed={datasetResponse.xmlMetadata.electronicSeal}
                  hstrcData={datasetResponse.metadata.hstrclData}
                  vlntry={datasetResponse.metadata.vlntry}
                />
              </div>
            </div>
          </div>
          {/*Metadata for diff dataflows*/}
          <div className="row d-flex justify-content-center">
            {datasetResponse.dataFlowType === DataFlowTypes.DATSSR ? (
              <DATSSRMetadata datasetResponse={datasetResponse} />
            ) : (
              <MetadataCard datasetResponse={datasetResponse} />
            )}
          </div>
        </div>
      </div>

      {/*Dataflow selection &File Selection */}
      {datasetResponse.dataFlowType === DataFlowTypes.DATSSR ? (
        <DATSSRView datasetResponse={datasetResponse} />
      ) : (
        <div className="IVWrapper py-3 m-3">
          <div className="container">
            <div className="row">
              <div className="col-12 bg-lighter rounded-2 py-3">
                {/*Select a file for view form */}
                <form>
                  <div className="input-group">
                    <select
                      className="form-select form-select-lg fw-semibold"
                      aria-label="Select dataset document"
                      value={selectedFileId ? selectedFileId : ""}
                      onChange={handleSelectFileChange}
                    >
                      <option value="" disabled>
                        {t(INFOVIEWER_DATASET_DETAILS_SELECT_DOCUMENT)}
                      </option>
                      {datasetResponse.files.map((file) => (
                        <option key={file.fileId} value={file.fileId}>
                          {file.displayName}
                        </option>
                      ))}
                    </select>
                    <button
                      className="btn btn-primary"
                      type="button"
                      disabled={
                        !hasRoles([Roles.INFORMATION_VIEWER_ROLE]) ||
                        !hasRoles([Roles.DOWNLOAD_SERVICE_ROLE]) ||
                        !selectedFileId
                      }
                      onClick={handleViewClick}
                    >
                      {t(INFOVIEWER_DATASET_DETAILS_VIEW_BUTTON)}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="row mt-3">
              {/* Add to cart and translate buttons */}
              <div className="col-12 bg-light rounded-2 py-3">
                <div className="d-flex justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-light border-0 mx-1"
                    onClick={() =>
                      handleDatasetFileDownload(
                        datasetResponse?.datasetId,
                        selectedFile
                      )
                    }
                    disabled={
                      isFileDownloading ||
                      !hasRoles([Roles.DOWNLOAD_SERVICE_ROLE]) ||
                      !selectedFile ||
                      contentError !== null
                    }
                  >
                    {isFileDownloading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        {t(INFOVIEWER_DATASET_DETAILS_DOWNLOADING_SPINNER)}
                      </>
                    ) : (
                      <>
                        <i
                          className="bi bi-download me-2"
                          aria-hidden="true"
                        ></i>
                        {t(INFOVIEWER_DATASET_DETAILS_DOWNLOAD_BUTTON)}
                      </>
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-light border-0 mx-1"
                    disabled={
                      isFileDownloading ||
                      isFileTranslating ||
                      !selectedFile ||
                      contentError !== null ||
                      !selectedFile ||
                      contentError !== null
                    }
                    onClick={handleModalOpen}
                  >
                    {isFileTranslating ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        {t(INFOVIEWER_DATASET_DETAILS_TRANSLATE_SPINNER)}
                      </>
                    ) : (
                      <>
                        <i
                          className="bi bi-translate me-2"
                          aria-hidden="true"
                        ></i>
                        {t(INFOVIEWER_DATASET_DETAILS_TRANSLATE_BUTTON)}
                      </>
                    )}
                  </button>
                </div>
                {/* Content of files Container */}
                <div
                  className="bg-white border rounded-1 mt-3" /*style={{ height: 900, paddingTop: 150 }}*/
                  style={{
                    height: "auto",
                    maxHeight: "900px",
                    padding: "20px",
                    overflow: "auto"
                  }}
                 >
                  {contentError && (
                   <div 
                   className="alert alert-danger mt-3 d-flex justify-content-center align-items-center" 
                   style={{ textAlign: "center", margin: "0 auto", width: "fit-content" }}
                 >
                   {contentError}
                 </div>
                 
                  )}

                  {loading && !contentError ? (
                       <div
                       style={{
                         display: "flex",
                         justifyContent: "center",
                         alignItems: "center",
                         height: "100%",
                       }}
                     >
                       <Spinner />
                     </div>
                  ) : (
                    <>
                      {!selectedFile && contentError == null && (
                        <div className="text-center text-primary mt-3">
                          <p>
                            {t(INFOVIEWER_DATASET_DETAILS_SELECT_FILE_MESSAGE)}
                          </p>
                        </div>
                      )}
                      {selectedFile && fileContent && (
                        <div>
                          {selectedFile.fileType === FileTypes.XML &&
                            (typeof fileContent === "string" ||
                              typeof fileContent === "object") && (
                              <XmlDisplay
                                fileContent={fileContent}
                                convertedToJson={
                                  datasetResponse.dataFlowType ===
                                  DataFlowTypes.DATSSR
                                }
                              />
                            )}
                          {selectedFile.fileType === FileTypes.XBRL &&
                            typeof fileContent === "string" && (
                              <XbrlDisplay
                                fileContent={fileContent as string}
                              />
                            )}
                          {selectedFile.fileType === FileTypes.ESEF_ZIP &&
                            typeof fileContent === "string" && (
                              <EsefZipDisplay
                                fileContent={fileContent as string}
                              />
                            )}
                          {selectedFile.fileType === FileTypes.TXT &&
                            typeof fileContent === "string" && (
                              <PlainTextDisplay
                                fileContent={fileContent as string}
                              />
                            )}
                          {selectedFile.fileType === FileTypes.PDF &&
                            fileContent instanceof Blob && (
                              <PdfDisplay fileContent={fileContent as Blob} />
                            )}
                          {selectedFile.fileType === FileTypes.JSON &&
                            typeof fileContent === "object" && (
                              <JsonDisplay
                                fileContent={fileContent as object}
                              />
                            )}
                          {(selectedFile.fileType === FileTypes.HTML ||
                            selectedFile.fileType === FileTypes.XHTML) &&
                            typeof fileContent === "string" && (
                              <HtmlDisplay
                                fileContent={fileContent as string}
                              />
                            )}
                        </div>
                      )}
                    </>
                  )}
                  {selectedFile &&
                    !loading &&
                    selectedFile.fileType === FileTypes.CSV && (
                      <CsvDisplay rows={csvRows} />
                    )}
                </div>
                {selectedFile && selectedFile.fileType === FileTypes.CSV && (
                  <div className="Pagination d-flex justify-content-end mt-3">
                    <div className="Pagination d-flex justify-content-end mt-3">
                      <button
                        className="btn btn-sm btn-light link-dark border-0 mx-1 d-none d-md-inline"
                        onClick={() => handlePagination("first")}
                        disabled={currentCsvPage === 0}
                      >
                        <i
                          className="bi bi-chevron-bar-left"
                          aria-hidden="true"
                        ></i>{" "}
                        {t(INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_FIRST_BUTTON)}
                      </button>
                      <button
                        className="btn btn-sm btn-light link-dark border-0 mx-1"
                        onClick={() => handlePagination("prev")}
                        disabled={currentCsvPage === 0}
                      >
                        <i
                          className="bi bi-chevron-left"
                          aria-hidden="true"
                        ></i>{" "}
                        {t(
                          INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_PREVIOUS_BUTTON
                        )}
                      </button>
                      <button
                        className="btn btn-sm btn-light link-dark border-0 mx-1"
                        onClick={() => handlePagination("next")}
                        disabled={currentCsvPage >= totalCsvPages}
                      >
                        <i
                          className="bi bi-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        {t(INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_NEXT_BUTTON)}
                      </button>
                      <button
                        className="btn btn-sm btn-light text-dark border-0 mx-1 d-none d-md-inline"
                        onClick={() => handlePagination("last")}
                        disabled={currentCsvPage >= totalCsvPages}
                      >
                        <i
                          className="bi bi-chevron-bar-right"
                          aria-hidden="true"
                        ></i>
                        {t(INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_LAST_BUTTON)}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Cart Success Toast */}
      <Toast
        message={t(DOWNLOAD_CART_ADD_TO_CART_MESSAGE)}
        isVisible={showAddToCartToast}
        onClose={() => setShowAddToCartToast(false)}
        type="success"
      />
      {/* Cart Remove Toast */}
      <Toast
        message={t( DOWNLOAD_CART_REMOVE_FROM_CART_MESSAGE)}
        isVisible={showRemoveFromCartToast}
        onClose={() => setShowRemoveFromCartToast(false)}
        type="success"
      />

      {/* Download Error Toast */}
      <Toast
        message={downloadError}
        isVisible={showDownloadErrorToast}
        onClose={() => {
          setShowDownloadErrorToast(false);
          setDownloadError(null);
        }}
        type="danger"
      />
      <TranslateModal
        show={showModal}
        onHide={handleModalClose}
        onTranslate={handleTranslationClick}
        from={from}
        setFrom={setFrom}
        to={to}
        setTo={setTo}
        isTranslating={isFileTranslating}
        fileDisplayName={selectedFile?.displayName ?? ""}
        fileSentForTranslation={fileSentForTranslation}
      />
    </main>
  );
};

export default DatasetDetails;
