import { useState, useRef, useContext, useEffect, useCallback } from "react";
import useCart from "../../services/CartSevice/CartService.ts";
import { bulkDownloadRequestService } from "../../services/DatasetDetailsService/DatasetDetailsService.ts";
import { checkBulkAvailability, downloadBulk } from "../../services/CartSevice/BulkDownloadService.ts";
import { Col, Container, Row, Button, Alert, Spinner, Modal,OverlayTrigger } from "react-bootstrap";
import { CartCountContext } from "../../context/CartCountContext.tsx";
import { useUserInfo } from "../../hooks/useUserInfo.ts";
import { UserInfo } from "../../model/UserInfo.ts";
import { DOWNLOAD_CART_ADD_ITEMS_MESSAGE, DOWNLOAD_CART_BULK_AVAILABILITY_ERROR_MESSAGE, DOWNLOAD_CART_BULK_AVAILABILITY_MESSAGE, DOWNLOAD_CART_CANCEL_BUTTON, DOWNLOAD_CART_CHECKOUT_BUTTON, DOWNLOAD_CART_CONFIRM_BUTTON, DOWNLOAD_CART_CONFIRM_CHECKOUT_MODAL_BODY, DOWNLOAD_CART_CONFIRM_CHECKOUT_MODAL_TITLE, DOWNLOAD_CART_CURRENTLY_EMPTY_CART_MESSAGE, DOWNLOAD_CART_DOWNLOAD_SPINNER, DOWNLOAD_CART_HOLD_WHILE_VALIDATE_MESSAGE, DOWNLOAD_CART_LIMITS_NUMBER_MESSAGE, DOWNLOAD_CART_LIMITS_SIZE_MESSAGE, DOWNLOAD_CART_MY_CART_LABEL, DOWNLOAD_CART_PROCESS_SPINNER, DOWNLOAD_CART_REMOVE_DATASET_BUTTON, DOWNLOAD_CART_REMOVE_DATASET_TOOLTIP,  DOWNLOAD_CART_VALIDATION_FAIL_MESSAGE, Roles } from "../../constants/EsapConstants.ts";
import CustomSpinner from "../DatasetDetails/common/Spinner.tsx";
import { CartItem } from "../../model/CartItem.ts";
import { validateCurrentCart } from "../../services/CartSevice/CartValidationService.ts";
import { CartValidationResult } from "../../model/CartValidations.tsx";
import { t } from "i18next";
import { renderTooltip } from "@/utils/tooltips.tsx";


const Cart: React.FC = () => {
  const [cartIsValid, setCartIsValid] = useState<boolean>(false);
  const validateInProgress = useRef<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null
  );
  const [validationError, setValidationError] = useState<boolean>(false);
  const [unavailableDatasets, setUnavailableDatasets] =
    useState<Set<string> | null>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { cartItems, deleteCartItem, deleteAllFromCart } = useCart();
  const [checkout, setCheckout] = useState<boolean>(false);
  const [polling, setPolling] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [bulkAvailableMsg, setBulkAvailableMsg] = useState<string | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const intervalPollingRef = useRef<NodeJS.Timeout | null>(null);
  const intervalPollingCounterRef = useRef<number>(0);
  const { count, setCount } = useContext(CartCountContext)!;
  const POLLING_LIMIT: number = 6;
  const POLLING_INTERVAL_MS: number = 10000;
  const { user } = useUserInfo();
  const [userInfo, setUserInfo] = useState<UserInfo | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);


  useEffect(() => {
    setUserInfo(user);
  }, [user, setUserInfo]);

  const hasRoles = useCallback(
    (roles: string[]) => {
      return userInfo?.roles.some((r) => roles.includes(r));
    },
    [userInfo?.roles]
  );
  
  const validCartCheck = useCallback(
    async (cartDatasets: CartItem[]): Promise<CartValidationResult | null> => {
      if (cartDatasets.length === 0 || validateInProgress.current) {
        resetValidationState();
        return null;
      }

      validateInProgress.current = true;
      setIsLoading(true);
      const validationResult = await validateCurrentCart(cartDatasets);
      if (validationResult) {
        setCartIsValid(validationResult.isValid);
        setShowAlert(validationResult.showAlert);
        setValidationMessage(validationResult.message);
        setValidationError(validationResult.error);
        setUnavailableDatasets(new Set(validationResult.unavailableDatasets));
      }
      setIsLoading(false);
      validateInProgress.current = false;
      return validationResult;
    },
    []
  );

  useEffect(() => {
    if (cartItems.length > 0 && hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
      validCartCheck(cartItems);
    }
  }, [cartItems, validCartCheck, hasRoles]);

  const handleRemoveItem = async (techRcrdIdr: string) => {
    if (!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
      return;
    }
    const updatedCartItems = deleteCartItem(techRcrdIdr);
    setCount(count > 0 ? count - 1 : 0);
    setCheckout(false);
    if (updatedCartItems.length === 0) {
      deleteAllFromCart();
      resetValidationState();
      return;
    }
    await validCartCheck(updatedCartItems);
  };

  const resetValidationState = () => {
    setCartIsValid(false);
    setValidationMessage("");
    setValidationError(false);
    setUnavailableDatasets(null);
    setShowAlert(false);
  };

  const stopPolling = useCallback(
    (clearJustPolling?: boolean) => {
      if (intervalPollingRef.current) {
        clearInterval(intervalPollingRef.current);
        intervalPollingRef.current = null;
      }
      intervalPollingCounterRef.current = 0;
      setPolling(false);
      if (!clearJustPolling) {
        setCheckout(false);
      }
    },
    [setPolling, setCheckout]
  );

  // Function to download the dataset
  const downloadBulkZip = useCallback(
    async (requestId: string) => {
      if (!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
        return;
      }
      try {
        setDownloading(true);
        const blob = await downloadBulk(requestId);

        // Handle file download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = bulkZipName();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        deleteAllFromCart();
        setCount(0);
      } catch (err) {
        const errorMessage = (err as Error).message;
        setError(errorMessage);
        console.error(err);
      } finally {
        setDownloading(false);
        stopPolling(true);
      }
    },
    [hasRoles, deleteAllFromCart, setCount, stopPolling]
  );

  const pollForBulk = useCallback(
    async (requestId: string) => {
      try {
        const data = await checkBulkAvailability(requestId);
        const { success } = data;

        if (success) {
          // Stop polling if the requestId is available
          stopPolling();
          setBulkAvailableMsg(null);
          await downloadBulkZip(requestId);
        } else {
          intervalPollingCounterRef.current =
            intervalPollingCounterRef.current + 1;
          // Continue the polling if the requestId is NOT available
          if (intervalPollingCounterRef.current >= POLLING_LIMIT) {
            intervalPollingCounterRef.current = POLLING_LIMIT;
            setBulkAvailableMsg(
              t(DOWNLOAD_CART_BULK_AVAILABILITY_MESSAGE)
            );
            stopPolling();
          }
        }
      } catch (err) {
        setError(t(DOWNLOAD_CART_BULK_AVAILABILITY_ERROR_MESSAGE));
        stopPolling();
      }
    },
    [stopPolling, downloadBulkZip]
  );

  const startPollingBulkDownload = useCallback(
    async (requestId: string) => {
      if (polling) {
        return;
      }
      setPolling(true);
      setError(null);
      setBulkAvailableMsg("Bulk is not ready yet.");
      await pollForBulk(requestId);

      intervalPollingRef.current = setInterval(async () => {
        await pollForBulk(requestId); // Call again every 10 seconds
      }, POLLING_INTERVAL_MS);
    },
    [polling, pollForBulk]
  );

  const startCheckoutProcess = useCallback(async () => {
    if (!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) return;

    try {
      setShowModal(false);
      // Ensure cart item limits and size limits are not exceeded
      if (cartItems.length > 3) {
        setShowAlert(true);
        return;
      }

      setCheckout(true);
      setError(null);
      const datasetIds = new Set(cartItems.map((item) => item.datasetId));
      const { isValid, showAlert, message, error, unavailableDatasets, uuid } =
        await bulkDownloadRequestService(datasetIds);

      setCartIsValid(isValid);
      setShowAlert(showAlert);
      setValidationMessage(message);
      setValidationError(error);
      setUnavailableDatasets(unavailableDatasets);

      // If the cart is valid and a UUID is returned ->Start polling for file availability
      if (isValid && uuid) {
        await startPollingBulkDownload(uuid);
      } else {
        // Stop the checkout process if validation fails
        setCheckout(false);
      }
    } catch (error) {
      setShowModal(false);
      let errorMsg = "Unexpected error.";
      if (error instanceof Error) {
        errorMsg = error.message;
      }
      setError(errorMsg);
      setCheckout(false);
    }
  }, [cartItems, hasRoles, setCheckout, startPollingBulkDownload]);

  const bulkZipName = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}.zip`;
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleOpenModal = async () => {
    if (!cartIsValid && hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
      const validationResult = await validCartCheck(cartItems);

      if (validationResult?.isValid) {
        setShowModal(true);
      } else {
        setValidationMessage(
          validationResult?.message ||
            t( DOWNLOAD_CART_VALIDATION_FAIL_MESSAGE)
        );
      }
    } else if (cartIsValid) {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => setShowModal(false);


  return (
    <main>
      <div className="Intro py-2 py-lg-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="fs-4 text-center">{t(DOWNLOAD_CART_MY_CART_LABEL)}</h1>
            </div>
          </div>
        </div>
      </div>

      <Container fluid="md">
        <Row className="my-5 justify-content-center">
          <Col xs={12} md={10}>
            {isLoading && (
              <div className="d-flex justify-content-center align-items-center flex-column">
                <div>{t(DOWNLOAD_CART_HOLD_WHILE_VALIDATE_MESSAGE)}</div>
                <CustomSpinner />
              </div>
            )}
            {showAlert && (
              <div
                className="alert alert-warning mt-5 d-flex align-items-center justify-content-center"
                role="alert"
              >
                <i
                  className="bi bi-exclamation-diamond fs-1 me-1"
                  aria-hidden="true"
                ></i>
                <span className="flex-grow-1 text-center">
                  {validationMessage}
                </span>
              </div>
            )}
            {validationError && (
              <div className="alert alert-danger mt-5 d-flex align-items-start py-0 pb-1">
                <i
                  className="bi bi-exclamation-diamond-fill fs-1 me-1 "
                  aria-hidden="true"
                ></i>
                <span className="flex-grow-1 px-3 pt-4 ">
                  <p>{validationMessage}</p>
                </span>
              </div>
            )}
            {bulkAvailableMsg && (
              <Alert variant="warning" className="mb-5">
                {bulkAvailableMsg}
              </Alert>
            )}
            {error && (
              <Alert
                variant="warning"
                onClose={() => setShowAlert(false)}
                dismissible
                className="mb-5"
              >
                {error}
              </Alert>
            )}

            {cartItems.length > 0 ? (
              <ul className="list-group list-group-flush my-5">
                {cartItems.map((item) => (
                  <li
                    key={item.datasetId}
                    className="list-group-item d-flex align-items-start py-3 py-lg-1"
                  >
                    <a
                      href="#"
                      className="d-lg-flex justify-content-between w-100"
                    >
                      {/* Classfication */}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          renderTooltip(item.rgltryDataTp.length > 0
                              ? item.rgltryDataTp
                                  .map((data) =>
                                    t(
                                      `INFOVIEWER_METADATA_NACE_TAXONOMY_SECTOR_${data.clssfctn}`,
                                      data.clssfctn
                                    )
                                  )
                                  .join(", ")
                              : "")
                          
                        }
                      >
                        <span
                          className={`SRT SRTa ${
                            unavailableDatasets?.has(item.datasetId)
                              ? "text-muted"
                              : ""
                          }`}
                        >
                          {t(
                            `INFOVIEWER_METADATA_NACE_TAXONOMY_SECTOR_${item.rgltryDataTp[0].clssfctn}`,
                            item.rgltryDataTp[0].clssfctn
                          )}
                        </span>
                      </OverlayTrigger>

                      {/* Legal Name */}
                      <span
                        className={`SRT SRTb ${
                          unavailableDatasets?.has(item.datasetId)
                            ? "text-muted"
                            : ""
                        }`}
                      >
                        {item.rltdNttyLglPrsnOrgMainNm || ""}
                      </span>

                      {/* LEI */}
                      <span
                        className={`SRT SRTc ${
                          unavailableDatasets?.has(item.datasetId)
                            ? "text-muted"
                            : ""
                        }`}
                      >
                        {item.rltdNttyLglPrsnLEI || ""}
                      </span>

                      {/* Date */}
                      <span
                        className={`SRT SRTd ${
                          unavailableDatasets?.has(item.datasetId)
                            ? "text-muted"
                            : ""
                        }`}
                      >
                        {item.rltdPrdToDt
                          ? `${formatDate(item.rltdPrdFrDt)} - ${formatDate(
                              item.rltdPrdToDt
                            )}`
                          : formatDate(item.rltdPrdFrDt)}
                      </span>
                    </a>
                      <OverlayTrigger
                      placement="top"
                      overlay={
                        
                         renderTooltip(t(DOWNLOAD_CART_REMOVE_DATASET_TOOLTIP))
                      
                      }
                    >
                      <button
                        type="button"
                        className="btn btn-sm btn-dark flex-shrink-1"
                        onClick={() => handleRemoveItem(item.datasetId)}
                        disabled={unavailableDatasets?.has(item.datasetId)}
                      >
                        <i className="bi bi-trash3" aria-hidden="true"></i>
                        <span className="visually-hidden">{t(DOWNLOAD_CART_REMOVE_DATASET_BUTTON)}</span>
                      </button>
                    </OverlayTrigger>
                  </li>
                ))}
              </ul>
            ) : (
              <div
                className="alert alert-info d-flex align-items-center"
                role="alert"
              >
                <i
                  className="bi bi-info-square "
                  style={{
                    fontSize: "35px",
                    color: "black",
                    marginRight: "15px",
                    marginTop: "-50px",
                  }}
                ></i>
                <div>
                  <p className="mb-1">{t(DOWNLOAD_CART_CURRENTLY_EMPTY_CART_MESSAGE)}</p>
                  <p className="mt-4 mb-0 ">
                    {t(DOWNLOAD_CART_ADD_ITEMS_MESSAGE)}
                  </p>
                  <p className="mb-0">
                    {t(DOWNLOAD_CART_LIMITS_NUMBER_MESSAGE)}
                    (<b>#</b>) {t(DOWNLOAD_CART_LIMITS_SIZE_MESSAGE)} (<b>#MB</b>).
                  </p>
                </div>
              </div>
            )}
            {cartItems.length > 0 && (
              <div className="d-flex justify-content-end mt-5">
                <Button
                  variant="primary"
                  onClick={handleOpenModal}
                  disabled={
                    !cartItems.length ||
                    checkout ||
                    downloading ||
                    polling ||
                    validationError ||
                    !cartIsValid
                  }
                >
                  {downloading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      {t(DOWNLOAD_CART_DOWNLOAD_SPINNER)}
                    </>
                  ) : checkout ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                     {t(DOWNLOAD_CART_PROCESS_SPINNER)}
                    </>
                  ) : (
                    t(DOWNLOAD_CART_CHECKOUT_BUTTON)
                  )}
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t(DOWNLOAD_CART_CONFIRM_CHECKOUT_MODAL_TITLE)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t(DOWNLOAD_CART_CONFIRM_CHECKOUT_MODAL_BODY)}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])}
            onClick={handleCloseModal}
          >
            {t(DOWNLOAD_CART_CANCEL_BUTTON)}
            </Button>
          <Button
            disabled={!hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])}
            variant="primary"
            onClick={startCheckoutProcess}
          >
            {t(DOWNLOAD_CART_CONFIRM_BUTTON)}
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default Cart;

