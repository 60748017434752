import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { t } from "i18next";
import { MONITORING_SUSPEND_RESUME_TABLE_HEADER_DATASET_ID,MONITORING_AVAILABILITY_TABLE_HEADER_AVAILABILITY_STATUS, MONITORING_AVAILABILITY_TABLE_HEADER_DOWNLOADABILITY_STATUS, MONITORING_AVAILABILITY_TABLE_HEADER_CHECK_TIMESTAMP, MONITORING_AVAILABILITY_TABLE_NO_RESULTS } from "@/constants/EsapConstants";

interface DataTableProps<TData> {
  data: TData[];
}

export function AvailabilityDownloadabilityTable<TData>({
    data,
}: DataTableProps<TData>) {


const columns =  [
                    {
                        accessorKey: "datasetId",
                        header: t(MONITORING_SUSPEND_RESUME_TABLE_HEADER_DATASET_ID),
                    },
                    {
                        accessorKey: "availabilityStatus",
                        header: t(MONITORING_AVAILABILITY_TABLE_HEADER_AVAILABILITY_STATUS),
                    },
                    {
                        accessorKey: "downloadabilityStatus",
                        header: t(MONITORING_AVAILABILITY_TABLE_HEADER_DOWNLOADABILITY_STATUS),
                    },
                    {
                        accessorKey: "timestamp",
                        header: t(MONITORING_AVAILABILITY_TABLE_HEADER_CHECK_TIMESTAMP),
                    }
                ];


const table = useReactTable({
    data,
    columns: columns,
    getCoreRowModel: getCoreRowModel()
});

  return (
    <>
        <div className="table-responsive">
            <table className="table table-sm FSsm">
                <thead className="table-light">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )
                                        }
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                            <>
                                <tr
                                    key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                >
                                    {row.getVisibleCells().map(cell => (
                                        <td key={cell.id}>
                                            {
                                                flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )
                                            }
                                        </td>
                                    ))}
                                </tr>
                            </>
                        ))
                        ) : (
                            <tr key="noResultsMessage">
                                <td key="noResultsMessageContent" colSpan={columns.length}>
                                {t(MONITORING_AVAILABILITY_TABLE_NO_RESULTS)}
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    </> 
  )
}