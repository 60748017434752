import './App.css';
import Header from "./components/Header/Header";
import Search from "./components/Search/Search";
import Footer from "./components/Footer/Footer";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Callback from "./components/Callback/Callback.tsx";
import LayoutWithAuth from "./components/LayoutWithAuth/LayoutWithAuth.tsx";
import NotFound from "./components/PageNotFound/PageNotFound.tsx";
import {EsapProvider} from "./context/EsapContextWrapper.tsx";
import ReportingEntities from "./components/ReportingEntities/ReportingEntities.tsx";
import Login from "./components/Login/Login.tsx";
import DatasetDetails from "./components/DatasetDetails/DatasetDetails.tsx";
import QualityMonitoring from "./components/Monitoring/QualityMonitoring.tsx";
import {Roles} from "./constants/EsapConstants.ts";
import Cart from "./components/Cart/Cart.tsx"
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import { CartCountProvider } from './context/CartCountContext.tsx';
import { EventUUIDProvider } from './context/EventUUIDContext';
import HistoryOfSubmission from "./components/HistoryOfSubmission/HistoryOfSubmission.tsx";
import QualityMonitoringHistorical from './components/Monitoring/QualityMonitoringHistorical.tsx';
import ReSubmission from "@/components/ReSubmission/ReSubmission.tsx";
import SuspendResumeResults from './components/Monitoring/SuspendResumeResults.tsx';
import AvailabilityMonitoring from './components/Monitoring/AvailabilityMonitoring.tsx'


function App() {
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <EsapProvider>
              <CartCountProvider>
                  <EventUUIDProvider>
                <BrowserRouter>
                        <div className="d-flex flex-column min-vh-100">
                            <Header/>
                            <Routes>
                                <Route path="/" element={
                                    <LayoutWithAuth
                                        comp={<Search />}
                                        allowedRoles={[Roles.WEB_PORTAL_ROLE, Roles.SEARCH_ENGINE_ROLE]}
                                    />
                                } />
                                <Route path="/Home" element={<Callback/>} />
                                <Route path="/search" element={<Navigate to="/" replace />} />
                                <Route path="/user/login" element={
                                        <LayoutWithAuth
                                            comp={<Login/>}
                                            allowedRoles={[]}
                                        />
                                    }
                                />
                                <Route
                                    path="/dataset/:datasetId"
                                    element={
                                        <LayoutWithAuth
                                            comp={<DatasetDetails />}
                                            allowedRoles={[Roles.INFORMATION_VIEWER_ROLE]}
                                        />
                                    }
                                />
                                <Route
                                    path="/data-quality-monitoring"
                                    element={
                                        <LayoutWithAuth
                                            comp={<QualityMonitoring />}
                                            allowedRoles={[Roles.ESAP_DATA_MANAGER_ROLE]}
                                        />
                                    }
                                />
                                <Route
                                    path="/data-quality-monitoring-historical"
                                    element={
                                        <LayoutWithAuth
                                            comp={<QualityMonitoringHistorical />}
                                            allowedRoles={[Roles.ESAP_DATA_MANAGER_ROLE]}
                                        />
                                    }
                                />
                                   <Route
                                    path="/availability-monitoring"
                                    element={
                                        <LayoutWithAuth
                                            comp={<AvailabilityMonitoring />}
                                            allowedRoles={[Roles.ESAP_DATA_MANAGER_ROLE]}
                                        />
                                    }
                                />
                                <Route
                                    path="/suspend-resume-results"
                                    element={
                                        <LayoutWithAuth
                                            comp={<SuspendResumeResults />}
                                            allowedRoles={[Roles.ESAP_DATA_MANAGER_ROLE]}
                                        />
                                    }
                                />
                                <Route path="/notFound" element={<NotFound />} />
                                <Route path="/report" element={
                                    <LayoutWithAuth
                                        comp={<ReportingEntities />}
                                        requiresAuth={true}
                                        allowedRoles={[Roles.ESAP_SUPPORT_ROLE, Roles.DATA_FLOW_ROLE  ]}
                                    />
                                } />
                                <Route path="/historyofsubmission" element={
                                    <LayoutWithAuth
                                        comp={<HistoryOfSubmission />}
                                        requiresAuth={true}
                                        allowedRoles={[Roles.ESAP_SUPPORT_ROLE, Roles.DATA_FLOW_ROLE  ]}
                                    />
                                } />
                                <Route path="/resubmission" element={
                                    <LayoutWithAuth
                                        comp={<ReSubmission />}
                                        requiresAuth={true}
                                        allowedRoles={[Roles.ESAP_SUPPORT_ROLE, Roles.DATA_FLOW_ROLE  ]}
                                    />
                                } />
                                <Route path="/cart" element={
                                    <LayoutWithAuth
                                        comp={<Cart />}
                                        allowedRoles={[Roles.DOWNLOAD_SERVICE_ROLE]}
                                    />
                                } />
                            </Routes>
                            <Footer/>
                        </div>
                </BrowserRouter>
                  </EventUUIDProvider>
               </CartCountProvider>
            </EsapProvider>
        </AppInsightsContext.Provider>
    );
}

export default App;
