import { INFOVIEWER_METADATA_CARD_COLLECTION_BODY_FIELD, INFOVIEWER_METADATA_CARD_LEGAL_FRAMEWORK_FIELD, INFOVIEWER_METADATA_CARD_SUBMISSION_DATE_FIELD, INFOVIEWER_METADATA_CARD_TYPE_OF_INFORMATION_FIELD, INFOVIEWER_METADATA_CARD_VERSION_FIELD, INFOVIEWER_METADATA_INDUSTRY_SECTOR_OF_POSITION_HOLDER_FIELD, INFOVIEWER_METADATA_LEGALFRAMEWORK_CODE, INFOVIEWER_METADATA_NACE_TAXONOMY_SECTOR, INFOVIEWER_METADATA_REGULATORYDATA_CLASSIFICATION } from "@/constants/EsapConstants";
import { DatasetResponse } from "../../../model/InfoviewerResult";
import {useTranslation} from "react-i18next";

interface DATSSRMetadataProps {
    datasetResponse: DatasetResponse;
}


const DATSSRMetadata: React.FC<DATSSRMetadataProps>=({ datasetResponse})=>{
    const {t} = useTranslation();
    
    const wrapText: React.CSSProperties={
        whiteSpace: "normal",
        wordBreak: "break-word",
        overflowWrap: "break-word",
        maxWidth: "100%",
      };
    return (
    
        <>
        <dl className="DList DList-Compact DList-Separators DList-6-6 DL2Cols mt-4">
        <dt style={wrapText}>{t(INFOVIEWER_METADATA_CARD_COLLECTION_BODY_FIELD)}</dt>
        <dd style={wrapText}>{datasetResponse?.metadata.colltnBody}</dd>
        <dt style={wrapText}>{t(INFOVIEWER_METADATA_CARD_TYPE_OF_INFORMATION_FIELD)}</dt>
        <dd style={wrapText}>{datasetResponse.metadata.rgltryDataTp.map(regulatoryData => t(`${INFOVIEWER_METADATA_REGULATORYDATA_CLASSIFICATION}_${regulatoryData.clssfctn}`,regulatoryData.clssfctn)).join(', ')}</dd>
        <dt style={wrapText}>{t(INFOVIEWER_METADATA_CARD_LEGAL_FRAMEWORK_FIELD)}</dt>
        <dd style={wrapText}>{datasetResponse?.metadata.rgltryDataTp.map(regulatoryType=>t(`${INFOVIEWER_METADATA_LEGALFRAMEWORK_CODE}_${regulatoryType.lglFrmwk}`,regulatoryType.lglFrmwk)).join(', ')}</dd>
        <dt style={wrapText}>{t(INFOVIEWER_METADATA_CARD_SUBMISSION_DATE_FIELD)}</dt> 
        <dd style={wrapText}>{datasetResponse?.metadata.submissnDtTm}</dd>
        <dt style={wrapText}>{t(INFOVIEWER_METADATA_INDUSTRY_SECTOR_OF_POSITION_HOLDER_FIELD)}:</dt>
        <dd style={wrapText}>{datasetResponse?.metadata.rltdNttyLglPrsnSctr.map(sectorData=>t(`${INFOVIEWER_METADATA_NACE_TAXONOMY_SECTOR}_${sectorData.nacetXnmySctr}`,sectorData.nacetXnmySctr)).join(', ')}</dd>
        <dt style={wrapText}>{t(INFOVIEWER_METADATA_CARD_VERSION_FIELD)}</dt>
        <dd style={wrapText}>{datasetResponse?.metadata.vrsn}</dd>  
        </dl>
        </>

    );};


  
    export default DATSSRMetadata;