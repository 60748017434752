import {fetchWrapper} from "@/services/FetchWrapperService/FetchWrapper.ts";


export const fetchFilterItems = async () => {
    try {
        const url = import.meta.env.VITE_REACT_APP_BACKEND_CORE_URL + `/historyofsubmission/getfilteritems`;
        const response = await fetchWrapper(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};


export const fetchResults = async () => {
    try {
        const url =
            //`http://localhost:53968/api/getresults`
            `${import.meta.env.VITE_REACT_APP_BACKEND_CORE_URL}/historyofsubmission/getresults`
        ;

        const response = await fetchWrapper(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(null), // Pass the filter here
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};


export const fetchDetails = async (fileId: string | null) => {
    try {
        fileId = encodeURIComponent(fileId as string);
        const url =
            `${import.meta.env.VITE_REACT_APP_BACKEND_CORE_URL}/historyofsubmission/getdetails?fileid=${fileId}`;

        const response = await fetchWrapper(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({fileId}), // Pass the fileId in the request body
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};


export const handleDownload = async (filename: string, downloadtype: string) => {
    try {
        filename = encodeURIComponent(filename);
        console.log(`Downloading ${filename}`);
        const endpoint =
            `${import.meta.env.VITE_REACT_APP_BACKEND_CORE_URL}/historyofsubmission/downloadfile?filename=${filename}&downloadtype=${downloadtype}`;
        const response = await fetchWrapper(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({filename, downloadtype}), // Pass the fileId in the request body
        });
        console.log(`${import.meta.env.VITE_REACT_APP_BACKEND_CORE_URL}/historyofsubmission/downloadfilee`);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        // Retrieve filename from the `Content-Disposition` header, if available
        const contentDisposition = response.headers.get('content-disposition');
        const fileName = contentDisposition
            ? contentDisposition.split('filename=')[1].replace(/"/g, '')
            : 'downloaded-file.ext'; // Default fallback filename

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Clean up the URL object
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error downloading file:", error);
    }
};

export const resendFeedback = async (filename: string) => {
    try {
        const endpoint =
            `${import.meta.env.VITE_REACT_APP_BACKEND_CORE_URL}/historyofsubmission/resendfeedback?filename=${filename}`;
            /*`http://localhost:53968/api/resendfeedback?filename=${filename}`;*/

        console.log(endpoint);
        const response = await fetchWrapper(endpoint);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching data-----:', error);
        throw error;
    }

};