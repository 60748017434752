import { DatasetResponse } from "../../../model/InfoviewerResult";
import { useEffect, useState } from "react";
import { DATSSRData } from "../../../model/DATSSRData";
import { dataForDATSSR } from "../../../services/DatasetDetailsService/DatasetDetailsService";
import { DataFlowTypes, FileTypes } from "../../../constants/EsapConstants";
import { Spinner } from "react-bootstrap";

interface DATSSRViewProps {
  datasetResponse: DatasetResponse | null;
}

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
};

const validForDATSSR = (datasetResponse: DatasetResponse | null): boolean => {
  if (datasetResponse) {
    return (
      datasetResponse.dataFlowType === DataFlowTypes.DATSSR &&
      datasetResponse.files.length == 1 &&
      datasetResponse.files[0].fileType === FileTypes.XML
    );
  } else {
    return false;
  }
};

const DATSSRView: React.FC<DATSSRViewProps> = ({ datasetResponse }) => {
  const [data, setData] = useState<DATSSRData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const errorMessage =
    "An error occurred while trying to display the information for this dataset.";

  useEffect(() => {
    const validDataset = validForDATSSR(datasetResponse);

    if (validDataset) {
      const fetchData = async () => {
        try {
          const result = await dataForDATSSR(
            datasetResponse!.datasetId,
            datasetResponse!.files[0].fileId
          );
          setData(result);
        } catch (err) {
          console.log(errorMessage);
          setError(errorMessage);
        }
      };
      fetchData();
    } else {
      console.log(errorMessage);
      setError(errorMessage);
    }
  }, [datasetResponse]);

  const wrapText: React.CSSProperties = {
    whiteSpace: "normal",
    wordBreak: "break-word",
    overflowWrap: "break-word",
    maxWidth: "100%",
  };

  if (error) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <h3>{error}</h3>
      </div>
    );
  } else if (!data) {
    return (
      <div className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center align-items-center">
                <Spinner />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <dl className="DList DList-Compact DList-Separators DList-6-6 DL2Cols">
                  <dt style={wrapText}>POSITION HOLDER LEI:</dt>
                  <dd style={wrapText}>{data?.rltdNttyLglPrsnLEI?data?.rltdNttyLglPrsnLEI:""}</dd>
                  <dt style={wrapText}>POSITION HOLDER NAME:</dt>
                  <dd style={wrapText}>
                    {data?.rltdNttyLglPrsnOrgMainNm?data?.rltdNttyLglPrsnOrgMainNm: "" } {/*|| data?.rltdNttyNtrlPrsnNm */}
                  </dd>
                  <dt style={wrapText}>POSITION HOLDER COUNTRY:</dt>
                  <dd style={wrapText}>{data?.homeCtry?data?.homeCtry:""}</dd>
                  <dt style={wrapText}>DATE OR PERIOD COVERED:</dt>
                  <dd style={wrapText}>
                    {data?.rltdPrdToDt
                      ? `${formatDate(data.rltdPrdFrDt)} - ${formatDate(
                          data?.rltdPrdToDt
                        )}`
                      : formatDate(datasetResponse!.metadata?.rltdPrdFrDt)}
                  </dd>
                  <dt style={wrapText}>ISIN:</dt>
                  <dd style={wrapText}>{data.rltdIdrIsin?data.rltdIdrIsin:""}</dd>
                  <dt style={wrapText}>NAME OF THE ISSUER:</dt>
                  <dd style={wrapText}>{data?.rltdIdrDesc?data?.rltdIdrDesc:""}</dd>
                  <dt style={wrapText}>NET SHORT POSITION PERCENTAGE:</dt>
                  <dd style={wrapText}>{data?.ntShrtPstnPrc?data?.ntShrtPstnPrc:""}</dd>
                </dl>
              </div>{" "}
              {/*col*/}
            </div>{" "}
            {/*row*/}
          </div>{" "}
          {/*container*/}
        </div>
      </>
    );
  }
};

export default DATSSRView;
