/* eslint-disable @typescript-eslint/no-explicit-any */

import { useUserInfo } from "@/hooks/useUserInfo";
import { useCallback, useEffect, useRef, useState } from "react";
import { UserInfo } from "../../model/UserInfo.ts";
import { MONITORING_DATA_QUALITY_FETCH_LATEST_REPORT_FAILED_MESSAGE, MONITORING_DATA_QUALITY_REPORT_IDENTIFIER, MONITORING_DATA_QUALITY_TABLE_HEADER_ACKNOWLEDGEMENT_STATUS, MONITORING_DATA_QUALITY_TABLE_HEADER_DATASET_ID, MONITORING_DATA_QUALITY_TABLE_HEADER_DATE_TIME, MONITORING_DATA_QUALITY_TABLE_HEADER_ERROR_MSG, MONITORING_DATA_QUALITY_TABLE_HEADER_FILETYPE_CODE, MONITORING_DATA_QUALITY_TABLE_HEADER_PRIORITY, MONITORING_DATA_QUALITY_TABLE_HEADER_PUBLICATION_STATUS, MONITORING_DATA_QUALITY_TABLE_HEADER_SEAL_VALIDATION_STATUS, MONITORING_DATA_QUALITY_TABLE_HEADER_STATUS, MONITORING_DATA_QUALITY_TABLE_PAGINATION_TEXT_1, MONITORING_DATA_QUALITY_TABLE_PAGINATION_TEXT_2, MONITORING_DATA_QUALITY_TABLE_PAGINATION_TEXT_3, MONITORING_DATA_QUALITY_TITLE, Roles } from "@/constants/EsapConstants.ts";
import { LatestReportResponse } from "@/model/MonitoringResults.ts";
import { fetchLatestReport, getQualityIssuesOfDataset } from "@/services/MonitoringService/MonitoringService.ts";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { ExpandableTable } from "./common/ExpandableTable/ExpandableTable.tsx";
import { TableDatasetRow, createDatasetRowsFromLatestReportResponse } from "./common/ExpandableTable/ExpandableTableData.ts";
import { t } from "i18next";
import { PaginationState } from "@tanstack/react-table";
import MonitoringHeader from "./common/MonitoringHeader.tsx";

const QualityMonitoring: React.FC = () => {
  // Datasets
  const [latestReportResponse, setLatestReportResponse] = useState<LatestReportResponse | null>(null);
  const [waitLatestReportResponse, setWaitLatestReportResponse] = useState<boolean>(true);
  const [tableData, setTableData] = useState<TableDatasetRow[]>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  // Common error message
  const [error, setError] = useState<string | null>(null);
  // User Info
  const {user} = useUserInfo();
  const [userInfo, setUserInfo] = useState<UserInfo | null>();
  // Used to have expanded only one row each time
  const expandedRowIndex = useRef("");

  useEffect(() => {
      setUserInfo(user);
  }, [user, setUserInfo]);

  const hasRoles = useCallback((roles: string[]) => {
      return userInfo?.roles.some((r) => roles.includes(r));
  }, [userInfo]);

  useEffect(() => {
    const getLatestReport = async () => {
      let errorMsg = t(MONITORING_DATA_QUALITY_FETCH_LATEST_REPORT_FAILED_MESSAGE);
      try {
        const response: LatestReportResponse | null = await fetchLatestReport(pagination);
        setWaitLatestReportResponse(false);
        if (!response) {
          setError(errorMsg);
        } else {
          setLatestReportResponse(response);
          setTableData(createDatasetRowsFromLatestReportResponse(response));
        }
      } catch (error) {
        if (error instanceof Error) {
          errorMsg = error.message;
        }
        setError(errorMsg);
        console.error(errorMsg);
      }
    };

    if (hasRoles([Roles.ESAP_DATA_MANAGER_ROLE])) {
      setWaitLatestReportResponse(true);
      getLatestReport();
    }
  }, [hasRoles, pagination]);

  function handlePageChangeLatest(action: string) {
    let pageIndex = pagination.pageIndex;
    const totalPages = Math.ceil((latestReportResponse?.totalResults ? latestReportResponse?.totalResults : 0) / pagination.pageSize);

    switch (action) {
      case "next":
        if (pagination.pageIndex < totalPages) {
          pageIndex += 1;
        }
        break;
      case "previous":
        if (pagination.pageIndex > 1) {
          pageIndex -= 1;
        }
        break;
      case "first":
        pageIndex = 1;
        break;
      case "last":
        pageIndex = totalPages;
        break;
      default:
        return;
    }

    if (pageIndex != pagination.pageIndex) {
      setPagination({pageIndex: pageIndex, pageSize: pagination.pageSize});
    }
  }

  function handlePageSizeChangeLatest(event: React.ChangeEvent<HTMLSelectElement>) {
    const newPageSize = parseInt(event.target.value, 10);
    if (newPageSize != pagination.pageSize) {
      setPagination({pageIndex: 1, pageSize: newPageSize});
    }
  }

  const [expandedRowState, setExpandedRowState] = useState<any>([]);

  const ExpandableColumnsLatest = () => { 
    return [
      {
        accessorKey: "datasetId",
        header: t(MONITORING_DATA_QUALITY_TABLE_HEADER_DATASET_ID),
        cell: ({ row, getValue }: any) => (
          <div
            style={{
              paddingLeft: `${row.depth * 2}rem`,
            }}
          >
            <button
                {...{
                  onClick: () => {
                    row.getToggleExpandedHandler()();
                    expandedRowIndex.current = row.id;
                    if (!row.getIsExpanded()) {
                      setExpandedRowState({ waitResponse: true });
                      getQualityIssuesOfDataset(latestReportResponse?.reportId, getValue(), true).then((qualityIssuesTable) => {
                        setExpandedRowState(qualityIssuesTable);
                      });
                    }
                  }
                }}
            >
              {row.getIsExpanded()
                ? <a className="btn btn-link ExpColl">{getValue()}</a>
                : <a className="btn btn-link ExpColl collapsed">{getValue()}</a>}
            </button>
          </div>
        )
      },
      {
        header: t(MONITORING_DATA_QUALITY_TABLE_HEADER_FILETYPE_CODE),
      },
      {
        header: t(MONITORING_DATA_QUALITY_TABLE_HEADER_DATE_TIME),
      },
      {
        header: t(MONITORING_DATA_QUALITY_TABLE_HEADER_ERROR_MSG),
      },
      {
        header: t(MONITORING_DATA_QUALITY_TABLE_HEADER_PUBLICATION_STATUS),
      },
      {
        header: t(MONITORING_DATA_QUALITY_TABLE_HEADER_SEAL_VALIDATION_STATUS),
      },
      {
        header: t(MONITORING_DATA_QUALITY_TABLE_HEADER_ACKNOWLEDGEMENT_STATUS),
      },
      {
        header: t(MONITORING_DATA_QUALITY_TABLE_HEADER_PRIORITY),
      },
      {
        header: t(MONITORING_DATA_QUALITY_TABLE_HEADER_STATUS),
      }
    ];
  }


  if (error) {
    return (
      <>
        <MonitoringHeader title = {MONITORING_DATA_QUALITY_TITLE}/>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            fontSize: "30px",
            fontWeight: "bold",
            color: "red",
            textAlign: "center",
          }}
        >
          {error}
        </div>
      </>
    );
  }
  
  if (waitLatestReportResponse) {
    return (
      <>
        <MonitoringHeader title = {MONITORING_DATA_QUALITY_TITLE}/>
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </>
    )
  }

  if (!waitLatestReportResponse && latestReportResponse?.reportId) {
    return (
      <> 
        <MonitoringHeader title = {MONITORING_DATA_QUALITY_TITLE}/>

        <div className="py-5">
          <Container>
            <Row className="py-3">
              <Col>
                <h2 className="mb-3 fs-5"><span className="fw-normal">{t(MONITORING_DATA_QUALITY_REPORT_IDENTIFIER)}:</span> {latestReportResponse?.reportId}</h2>
                
                {/* TABLE - RESULTS */}
                <ExpandableTable
                  data={tableData}
                  columns={ExpandableColumnsLatest()}
                  expandedRowContent={expandedRowState}
                  expandedRowIndex={expandedRowIndex.current}
                  reportId={latestReportResponse?.reportId}
                  acknowledgementStatusOptions={latestReportResponse?.acknowledgementStatusOptions}
                  priorityOptions={latestReportResponse?.priorityOptions}
                  statusOptions={latestReportResponse?.statusOptions}
                  isLatest = {true}
                />

                {/* PAGINATION */}
                <div className="d-flex align-items-center justify-content-end gap-2 pt-2">
                  <button
                    className="btn border rounded p-1"
                    onClick={() => handlePageChangeLatest("first")}
                    disabled={pagination.pageIndex === 1}
                  >
                    {'<<'}
                  </button>

                  <button
                    className="btn border rounded p-1"
                    onClick={() => handlePageChangeLatest("previous")}
                    disabled={pagination.pageIndex === 1}
                  >
                    {'<'}
                  </button>

                  <button
                    className="btn border rounded p-1"
                    onClick={() => handlePageChangeLatest("next")}
                    disabled={pagination.pageIndex * pagination.pageSize >= latestReportResponse.totalResults}
                  >
                    {'>'}
                  </button>

                  <button
                    className="btn border rounded p-1"
                    onClick={() => handlePageChangeLatest("last")}
                    disabled={pagination.pageIndex * pagination.pageSize >= latestReportResponse.totalResults}
                  >
                    {'>>'}
                  </button>

                  <span className="d-flex align-items-center gap-1">
                    <div>{t(MONITORING_DATA_QUALITY_TABLE_PAGINATION_TEXT_1)}</div>
                    {pagination.pageIndex} {t(MONITORING_DATA_QUALITY_TABLE_PAGINATION_TEXT_2)}{' '} {Math.floor(latestReportResponse.totalResults / pagination.pageSize) + 1}
                  </span>
          
                  <select style={{width: "auto"}} className="form-select form-select-sm"
                    value={pagination.pageSize}
                    onChange={handlePageSizeChangeLatest}
                  >
                    {[10, 20, 40].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        {t(MONITORING_DATA_QUALITY_TABLE_PAGINATION_TEXT_3)} {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
  }
}

export default QualityMonitoring;