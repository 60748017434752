import { SuspendResumeResultsResponse } from "@/model/SuspendResumeResults";
import { formatDate } from "../DateAndTimeFormat";

type SuspendResumeObject = {
    status: string;
    searchSuspendResumedUpdated: boolean;
    downloadSuspendResumedUpdated: boolean
}

export type SuspendResumeTableRow = {
    datasetId: string;
    version: string;
    suspendResumeTimestamp: string;
    publicationStatus: string;
    suspendResume: SuspendResumeObject;
}

export const createTableRowsFromSuspendResumeResultsResponse = (response: SuspendResumeResultsResponse): SuspendResumeTableRow[] => {
    const tableRows: SuspendResumeTableRow[] = [];
    if (response && response.reportId && response.results.length) {
        response.results.forEach((result) => {
            const row: SuspendResumeTableRow = {
                datasetId: result.datasetId,
                version: result.version,
                suspendResumeTimestamp: result.suspendResumeTimestamp ? formatDate(new Date(result.suspendResumeTimestamp)) : "",
                publicationStatus: result.publicationStatus,
                suspendResume: {status: result.status, searchSuspendResumedUpdated: result.searchSuspendResumedUpdated, downloadSuspendResumedUpdated: result.downloadSuspendResumedUpdated},
            };
            tableRows.push(row);
        });
    }
    return tableRows;
}