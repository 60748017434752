import { MONITORING_SUSPEND_RESUME_CURRENT_REPORT_IDENTIFIER, MONITORING_SUSPEND_RESUME_FETCH_RESULTS_FAILED_MESSAGE, MONITORING_SUSPEND_RESUME_RESULTS_TITLE, MONITORING_SUSPEND_RESUME_TABLE_PAGINATION_TEXT_1, MONITORING_SUSPEND_RESUME_TABLE_PAGINATION_TEXT_2, MONITORING_SUSPEND_RESUME_TABLE_PAGINATION_TEXT_3, Roles } from "@/constants/EsapConstants";
import MonitoringHeader from "./common/MonitoringHeader";
import { useUserInfo } from "@/hooks/useUserInfo";
import { SuspendResumeResultsResponse } from "@/model/SuspendResumeResults";
import { PaginationState } from "@tanstack/react-table";
import { UserInfo } from "../../model/UserInfo.ts";
import { useCallback, useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { fetchLatestSuspendResumeReport, fetchSpecificSuspendResumeReport } from "@/services/MonitoringService/MonitoringService.ts";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { SuspendResumeTable } from "./common/SuspendResumeTable/SuspendResumeTable.tsx";
import { createTableRowsFromSuspendResumeResultsResponse, SuspendResumeTableRow } from "./common/SuspendResumeTable/SuspendResumeTableData.ts";

const SuspendResumeResults: React.FC = () => {
    // Suspend/Resume Results
    const [suspendResumeResultsResponse, setSuspendResumeResultsResponse] = useState<SuspendResumeResultsResponse | null>(null);
    const [waitSuspendResumeResultsResponse, setWaitSuspendResumeResultsResponse] = useState<boolean>(true);
    const [tableData, setTableData] = useState<SuspendResumeTableRow[]>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 10,
    });
    const [reportIdSelected, setReportIdSelected] = useState<number>(0);
    // Common error message
    const [error, setError] = useState<string | null>(null);
    // User Info
    const {user} = useUserInfo();
    const [userInfo, setUserInfo] = useState<UserInfo | null>();
    // Used for reportId navigation
    const maxReportId = useRef(0);
    const currentReportId = useRef(0);

    useEffect(() => {
        setUserInfo(user);
    }, [user, setUserInfo]);
    
    const hasRoles = useCallback((roles: string[]) => {
        return userInfo?.roles.some((r) => roles.includes(r));
    }, [userInfo]);
    
    useEffect(() => {
        const getSuspendResumeReport = async () => {
            let errorMsg = t(MONITORING_SUSPEND_RESUME_FETCH_RESULTS_FAILED_MESSAGE);
            try {
                let response: SuspendResumeResultsResponse | null;
                if (reportIdSelected !== 0) {
                    response = await fetchSpecificSuspendResumeReport(pagination, reportIdSelected.toString());
                } else {
                    response = await fetchLatestSuspendResumeReport(pagination);
                }
                setWaitSuspendResumeResultsResponse(false);
                if (!response) {
                    setError(errorMsg);
                } else {
                    setSuspendResumeResultsResponse(response);
                    currentReportId.current = Number(response.reportId);
                    if (maxReportId.current === 0) {
                        maxReportId.current = Number(response.reportId);
                    }
                    setTableData(createTableRowsFromSuspendResumeResultsResponse(response));
                }
            } catch (error) {
                if (error instanceof Error) {
                    errorMsg = error.message;
                }
                setError(errorMsg);
                console.error(errorMsg);
            }
        };
   
        if (hasRoles([Roles.ESAP_DATA_MANAGER_ROLE])) {
            setWaitSuspendResumeResultsResponse(true);
            getSuspendResumeReport();
        }
    }, [hasRoles, pagination, reportIdSelected]);

    function handlePageChangeSuspendResume(action: string) {
        let pageIndex = pagination.pageIndex;
        const totalPages = Math.ceil((suspendResumeResultsResponse?.totalResults ? suspendResumeResultsResponse?.totalResults : 0) / pagination.pageSize);

        switch (action) {
        case "next":
            if (pagination.pageIndex < totalPages) {
                pageIndex += 1;
            }
            break;
        case "previous":
            if (pagination.pageIndex > 1) {
                pageIndex -= 1;
            }
            break;
        case "first":
            pageIndex = 1;
            break;
        case "last":
            pageIndex = totalPages;
            break;
        default:
            return;
        }

        if (pageIndex != pagination.pageIndex) {
            setPagination({pageIndex: pageIndex, pageSize: pagination.pageSize});
        }
    }

    function handlePageSizeChangeSuspendResume(event: React.ChangeEvent<HTMLSelectElement>) {
        const newPageSize = parseInt(event.target.value, 10);
        if (newPageSize != pagination.pageSize) {
            setPagination({pageIndex: 1, pageSize: newPageSize});
        }
    }

    function handleReportChangeSuspendResume(action: string) {
        switch (action) {
            case "next":
                if (currentReportId.current < maxReportId.current) {
                    setReportIdSelected(currentReportId.current+1);
                }
                break;
            case "previous":
                if (currentReportId.current > 1) {
                    setReportIdSelected(currentReportId.current-1);
                }
                break;
            case "first":
                setReportIdSelected(1);
                break;
            case "last":
                setReportIdSelected(maxReportId.current);
                break;
            default:
                return;
        }
    }
    

    if (error) {
        return (
        <>
            <MonitoringHeader title = {MONITORING_SUSPEND_RESUME_RESULTS_TITLE}/>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "red",
                    textAlign: "center",
                }}
            >
                {error}
            </div>
        </>
        );
    }
    
    if (waitSuspendResumeResultsResponse) {
        return (
        <>
            <MonitoringHeader title = {MONITORING_SUSPEND_RESUME_RESULTS_TITLE}/>
            <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        </>
        )
    }

    if (!waitSuspendResumeResultsResponse && suspendResumeResultsResponse) {
        return (
        <> 
            <MonitoringHeader title = {MONITORING_SUSPEND_RESUME_RESULTS_TITLE}/>

            <div className="py-5">
                <Container>
                    <Row className="py-3">
                        <Col>
                            {/* SELECT REPORT ID */}
                            <div className="my-2">
                                <span className="fw-normal mb-3 fs-5">{t(MONITORING_SUSPEND_RESUME_CURRENT_REPORT_IDENTIFIER)}:</span>

                                <button
                                    className="btn border rounded p-1"
                                    style={{
                                        marginLeft: "0.5rem",
                                    }}
                                    onClick={() => handleReportChangeSuspendResume("first")}
                                    disabled={currentReportId.current === 1}
                                >
                                    {'<<'}
                                </button>

                                <button
                                    className="btn mx-2 border rounded p-1"
                                    onClick={() => handleReportChangeSuspendResume("previous")}
                                    disabled={currentReportId.current === 1}
                                >
                                    {'<'}
                                </button>
                            
                                {currentReportId.current}
                            
                                <button
                                    className="btn mx-2 border rounded p-1"
                                    onClick={() => handleReportChangeSuspendResume("next")}
                                    disabled={currentReportId.current === maxReportId.current}
                                >
                                    {'>'}
                                </button>

                                <button
                                    className="btn border rounded p-1"
                                    style={{
                                        marginLeft: "0.5rem",
                                    }}
                                    onClick={() => handleReportChangeSuspendResume("last")}
                                    disabled={currentReportId.current === maxReportId.current}
                                >
                                    {'>>'}
                                </button>
                            </div>

                            {/* TABLE - RESULTS */}
                            <SuspendResumeTable
                                data={tableData}
                            />

                            {/* PAGINATION */}
                            <div className="d-flex align-items-center justify-content-end gap-2 pt-2">
                                <button
                                    className="btn border rounded p-1"
                                    onClick={() => handlePageChangeSuspendResume("first")}
                                    disabled={pagination.pageIndex === 1}
                                >
                                    {'<<'}
                                </button>

                                <button
                                    className="btn border rounded p-1"
                                    onClick={() => handlePageChangeSuspendResume("previous")}
                                    disabled={pagination.pageIndex === 1}
                                >
                                    {'<'}
                                </button>

                                <button
                                    className="btn border rounded p-1"
                                    onClick={() => handlePageChangeSuspendResume("next")}
                                    disabled={pagination.pageIndex * pagination.pageSize >= suspendResumeResultsResponse.totalResults}
                                >
                                    {'>'}
                                </button>

                                <button
                                    className="btn border rounded p-1"
                                    onClick={() => handlePageChangeSuspendResume("last")}
                                    disabled={pagination.pageIndex * pagination.pageSize >= suspendResumeResultsResponse.totalResults}
                                >
                                    {'>>'}
                                </button>

                                <span className="d-flex align-items-center gap-1">
                                    <div>{t(MONITORING_SUSPEND_RESUME_TABLE_PAGINATION_TEXT_1)}</div>
                                    {pagination.pageIndex} {t(MONITORING_SUSPEND_RESUME_TABLE_PAGINATION_TEXT_2)}{' '} {Math.floor(suspendResumeResultsResponse.totalResults / pagination.pageSize) + 1}
                                </span>
                        
                                <select style={{width: "auto"}} className="form-select form-select-sm"
                                    value={pagination.pageSize}
                                    onChange={handlePageSizeChangeSuspendResume}
                                >
                                    {[10, 20, 40].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {t(MONITORING_SUSPEND_RESUME_TABLE_PAGINATION_TEXT_3)} {pageSize}
                                    </option>
                                    ))}
                                </select>
                            </div>
                        
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
        )
    }
}



export default SuspendResumeResults;