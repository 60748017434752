import React, {useEffect, useState} from "react";
import {UserInfoContext} from "./UserInfoContext";
import {getUserInfo} from "../services/UserInfoService/UserInfoService";
import {UserInfo} from "../model/UserInfo";
import accessTokenService from "../services/AccessTokenService/AccessTokenService.ts";
import localStorageUUIDService from "../services/LocalStorageUUIDService/LocalStorageUUIDService.ts";
import {useAuth} from "react-oidc-context";
import {LS_ACCESS_TOKEN, ReadableRoleNames} from "../constants/EsapConstants.ts";
import { AnonymousRolesResponse } from "@/model/AnonymousRolesResponse.ts";
import { AnonymousRole } from "@/model/AnonymousRole.ts";

export const UserInfoProvider = ({children}: { children: React.ReactNode }) => {
    const [user, setUser] = useState<UserInfo | null>(null);
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {

        let isMounted = true;

        (async () => {

            if (!auth || auth.activeNavigator || auth.isLoading) {
                return;
            }
            const checkUserInfoUrl = import.meta.env.VITE_REACT_APP_USERINFO_URL;
                // const checkUserInfoUrl = "http://localhost:35020/esap-openid/userinfo";
    
                // GET user info
                try {
                    const response = await fetch(checkUserInfoUrl, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                    if (!isMounted) return;
                    if (response.ok) {
                        const data = await response.json();
                        if (data && data.roles && auth && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
                            // if ( auth && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
                            accessTokenService.clearOidcTokens();
                            // if (data.roles.includes("administrator")) {
                            //     auth.signinRedirect().then(() => {
                            //         console.log("Redirecting to login page");
                            //     });
                            // } else{
                            //     auth.signinSilent();
                            // }
                            await auth.signinRedirect();
                            console.log("Redirecting to login page");
    
                        } else if (data && data.message && auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
                            await auth.signoutSilent();
                        }

                        return data;
                    } else {
                        console.error("Error fetching user info:", response.statusText);
                    }
                } catch (error) {
                    console.error("Fetch error:", error);
                }
            
        })();

        return () => {
            isMounted = false;  // Clean up on component unmount
        };
    }, [auth]);

    useEffect(() => {
        if (auth.user && auth.user.access_token && auth.isAuthenticated) {
            const userInfo = {...getUserInfo(auth.user.access_token), isAuthenticated: auth.isAuthenticated};
            accessTokenService.setAccessToken({[LS_ACCESS_TOKEN]: auth.user.access_token});
            localStorageUUIDService.setUserUuid();
            setUser(userInfo);
            setIsLoading(false);
        } else {
            if (!auth || auth.activeNavigator || auth.isLoading) {
                return;
            }
            (async () => {
                const getAnonymousRolesUrl = import.meta.env.VITE_REACT_AZURE_GATEWAY_URL + import.meta.env.VITE_REACT_APP_ANONYMOUS_ROLES_URL_PATH;
                let failedToFetch = false;
                try {
                    const response = await fetch(getAnonymousRolesUrl, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    
                    if (response.ok) {
                        const data: AnonymousRolesResponse = await response.json();
                        if (data) {
                            setUser({
                                name: "",
                                username: "",
                                roles: (data.anonymous && data.anonymous.length > 0) ? data.anonymous.map((role: AnonymousRole) => role.value) : [],
                                rolesReadable: (data.anonymous && data.anonymous.length > 0) ? data.anonymous.map((role: AnonymousRole) => ReadableRoleNames[role.value]) : [],
                                picture: "",
                                email: ""
                            });
                            localStorageUUIDService.setUserUuid();
                        } else {
                            failedToFetch = true;
                            console.error("No anonymous user data", response.statusText);
                        }
                    } else {
                        failedToFetch = true;
                        console.error("Error fetching anonymous user roles:", response.statusText);
                    }
                } catch (error) {
                    failedToFetch = true;
                    console.error("Fetch error:", error);
                } finally {
                    setIsLoading(false);
                }
                if(failedToFetch) {
                    setUser({
                        name: "",
                        username: "",
                        roles: [],
                        rolesReadable: [],
                        picture: "",
                        email: ""
                    });
                }
            })();
        }
    }, [auth]);

    useEffect(() => {
        if (user) {
            console.log("User roles:", user.roles);
        }
    }, [user]);

    return (

        <UserInfoContext.Provider value={{user, setUser}}>
            {!isLoading && children}
        </UserInfoContext.Provider>
    );
};
