export const LS_USER_PHOTO: string = "USER_PHOTO";
export const CK_LANGUAGE: string = "LANGUAGE";
export const LS_ACCESS_TOKEN: string = "accessToken";
export const LS_ID_TOKEN: string = "idToken";
export const LS_LOGOUT_TOKEN: string = "logoutToken";
export const LS_USER_UUID: string = "user-uuid"

export const DEFAULT_LANGUAGE: string = "en";
export const FALLBACK_LANGUAGE: string = "en";

// Labels
// HEADER
export const ABOUT_LABEL: string = "UI_ABOUT_LABEL";
export const DOCUMENTATION_AND_SUPPORT_LABEL: string = "UI_DOCUMENTATION_AND_SUPPORT_LABEL";
export const FEEDBACK_LABEL: string = "UI_FEEDBACK_LABEL";
export const SUBMISSIONS_LABEL: string = "UI_SUBMISSIONS_LABEL";
export const REPORTING_ENTITIES_LABEL: string = "UI_REPORTING_ENTITIES_LABEL";
export const FILE_UPLOAD_LABEL_AND_HISTORY_OF_SUBMISSIONS_LABEL: string = "UI_FILE_UPLOAD_LABEL_AND_HISTORY_OF_SUBMISSIONS_LABEL";
export const ACCOUNT_PREFERENCES_LABEL: string = "UI_ACCOUNT_PREFERENCES_LABEL";
export const USER_ROLE_LABEL: string = "UI_USER_ROLE_LABEL";
export const LOGIN_LABEL: string = "UI_LOGIN_LABEL";
export const LOGIN_WITH_ENTRA_ID_LABEL: string = "UI_LOGIN_WITH_ENTRA_ID_LABEL";
export const LOGOUT_LABEL: string = "UI_LOGOUT_LABEL";
export const SYSTEM_ADMINISTRATION_LABEL: string = "UI_SYSTEM_ADMINISTRATION_LABEL";
export const TEST_SUBMISSION_OF_ESAP_INFO_LABEL: string = "UI_TEST_SUBMISSION_OF_ESAP_INFO_LABEL";
export const MANAGE_ONLINE_HELP_AND_TUTORIALS: string = "UI_MANAGE_ONLINE_HELP_AND_TUTORIALS";
export const MANAGE_PUBLIC_REFERENCE_DOCUMENTATION: string = "UI_MANAGE_PUBLIC_REFERENCE_DOCUMENTATION";
export const FILE_RESUBMISSION: string = "UI_FILE_RESUBMISSION";
export const ELEMENTS_VISIBILITY_CONTROL: string = "UI_ELEMENTS_VISIBILITY_CONTROL";
export const NOTIFICATION_MANAGEMENT: string = "UI_NOTIFICATION_MANAGEMENT";
export const UPDATE_ON_USERS_REQUESTS: string = "UI_UPDATE_ON_USERS_REQUESTS";
export const SYSTEM_MONITORING_LABEL: string = "UI_SYSTEM_MONITORING_LABEL";
export const MONITORING_REPORT_RESULTS_LABEL: string = "UI_MONITORING_REPORT_RESULTS_LABEL";
export const DATA_QUALITY_AND_MONITORING_LABEL: string = "UI_DATA_QUALITY_AND_MONITORING_LABEL";
export const DATA_QUALITY_HISTORICAL_AND_MONITORING_LABEL: string = "UI_DATA_QUALITY_HISTORICAL_AND_MONITORING_LABEL";
export const SUSPEND_RESUME_RESULTS_LABEL: string = "UI_SUSPEND_RESUME_RESULTS_LABEL";
export const AVAILABILITY_MONITORING_LABEL: string = "UI_AVAILABILITY_MONITORING_LABEL";
export const DASHBOARDS_LABEL: string = "UI_DASHBOARDS_LABEL";
export const DATA_PUBLICATION_STATUS_LABEL: string = "UI_DATA_PUBLICATION_STATUS_LABEL";

// SEARCH
export const SEARCH_HEADER_TEXT: string = "UI_SEARCH_HEADER_TEXT";
export const SEARCH_PLACEHOLDER_TEXT: string = "UI_SEARCH_PLACEHOLDER_TEXT";
export const SEARCH_TOOLTIP_TEXT: string = "UI_SEARCH_TOOLTIP_TEXT";
export const SEARCH_FILTERS_TEXT: string = "UI_SEARCH_FILTERS_TEXT";
export const SEARCH_MORE_FILTERS_TEXT: string = "UI_SEARCH_MORE_FILTERS_TEXT";
export const SEARCH_BUTTON_TEXT: string = "UI_SEARCH_BUTTON_TEXT";
export const SEARCH_CLEAR_BUTTON_TEXT: string = "UI_CLEAR_BUTTON_TEXT";

export const INFOVIEWER_PRSNL_DATA_YES_TEXT = 'YES';
export const INFOVIEWER_PRSNL_DATA_NO_TEXT = 'NO';
export const INFOVIEWER_PASSPORTED_INDICATOR_TRUE_TEXT = 'TRUE';
export const INFOVIEWER_PASSPORTED_INDICATOR_FALSE_TEXT = 'FALSE';

export const INFOVIEWER_METADATA_TAXONOMY_SECTOR_KEY_PREFIX_TEXT = "UI_INFOVIEWER_METADATA_NACE_TAXONOMY_SECTOR";
export const INFOVIEWER_METADATA_TAXONOMY_LG_FRMWRK_KEY_PREFIX_TEXT = "UI_INFOVIEWER_METADATA_LEGALFRAMEWORK_CODE";
export const INFOVIEWER_METADATA_TAXONOMY_REGDATA_CLSFN_KEY_PREFIX_TEXT = "UI_INFOVIEWER_METADATA_REGULATORYDATA_CLASSIFICATION";

// Filters
export const SEARCH_FILTERS_TEST_LABEL: string = "UI_SEARCH_FILTERS_TEST_LABEL";
export const SEARCH_FILTERS_TEST_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_TEST_TOOLTIP_TEXT";
export const SEARCH_FILTERS_DATE_RANGE_LABEL: string = "UI_SEARCH_FILTERS_DATE_RANGE_LABEL";
export const SEARCH_FILTERS_DATE_RANGE_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_DATE_RANGE_TOOLTIP_TEXT";
export const SEARCH_FILTERS_TYPE_OF_INFORMATION_LABEL: string = "UI_SEARCH_FILTERS_TYPE_OF_INFORMATION_LABEL";
export const SEARCH_FILTERS_TYPE_OF_INFORMATION_LABEL_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_TYPE_OF_INFORMATION_LABEL_TOOLTIP_TEXT";
export const SEARCH_FILTERS_FREE_TEXT_LABEL: string = "UI_SEARCH_FILTERS_FREE_TEXT_LABEL";
export const SEARCH_FILTERS_FREE_TEXT_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_FREE_TEXT_TOOLTIP_TEXT";
export const SEARCH_FILTERS_DATE_RANGE_FROM_PLACEHOLDER: string = "UI_SEARCH_FILTERS_DATE_RANGE_FROM_PLACEHOLDER";
export const SEARCH_FILTERS_DATE_RANGE_TO_PLACEHOLDER: string = "UI_SEARCH_FILTERS_DATE_RANGE_TO_PLACEHOLDER";
export const SEARCH_FILTERS_ENTITY_LEI: string = "UI_SEARCH_FILTERS_ENTITY_LEI";
export const SEARCH_FILTERS_ENTITY_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_ENTITY_LEI_TOOLTIP_TEXT";
export const SEARCH_FILTERS_ENTITY_NAME: string = "UI_SEARCH_FILTERS_ENTITY_NAME";
export const SEARCH_FILTERS_ENTITY_NAME_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_ENTITY_NAME_TOOLTIP_TEXT";
export const SEARCH_FILTERS_SIZE_OF_ENTITY: string = "UI_SEARCH_FILTERS_SIZE_OF_ENTITY";
export const SEARCH_FILTERS_SIZE_OF_ENTITY_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_SIZE_OF_ENTITY_TOOLTIP_TEXT";
export const SEARCH_FILTERS_SUBMISSION_DATE: string = "UI_SEARCH_FILTERS_SUBMISSION_DATE";
export const SEARCH_FILTERS_SUBMISSION_DATE_FROM: string = "UI_SEARCH_FILTERS_SUBMISSION_DATE_FROM";
export const SEARCH_FILTERS_SUBMISSION_DATE_TO: string = "UI_SEARCH_FILTERS_SUBMISSION_DATE_TO";
export const SEARCH_FILTERS_SUBMISSION_DATE_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_SUBMISSION_DATE_TOOLTIP_TEXT";
export const SEARCH_FILTERS_DATE_COVERED: string = "UI_SEARCH_FILTERS_DATE_COVERED";
export const SEARCH_FILTERS_DATE_COVERED_FROM: string = "UI_SEARCH_FILTERS_DATE_COVERED_FROM";
export const SEARCH_FILTERS_DATE_COVERED_TO: string = "UI_SEARCH_FILTERS_DATE_COVERED_TO";
export const SEARCH_FILTERS_DATE_COVERED_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_DATE_COVERED_TOOLTIP_TEXT";
export const SEARCH_FILTERS_SUBMITTING_ENTITY_NAME: string = "UI_SEARCH_FILTERS_SUBMITTING_ENTITY_NAME";
export const SEARCH_FILTERS_COUNTRY_OF_REGISTERED_OFFICE: string = "UI_SEARCH_FILTERS_COUNTRY_OF_REGISTERED_OFFICE";
export const SEARCH_FILTERS_COUNTRY_OF_REGISTERED_OFFICE_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_COUNTRY_OF_REGISTERED_OFFICE_TOOLTIP_TEXT";
export const SEARCH_FILTERS_ORIGINAL_LANGUAGE: string = "UI_SEARCH_FILTERS_ORIGINAL_LANGUAGE";
export const SEARCH_FILTERS_ORIGINAL_LANGUAGE_TOOLTIP_TEXT: string = "UI_SEARCH_FILTERS_ORIGINAL_LANGUAGE_TOOLTIP_TEXT";
export const SEARCH_SUBMITTING_ENTITY_NAME: string = "UI_SEARCH_SUBMITTING_ENTITY_NAME";
export const SEARCH_SUBMITTING_ENTITY_NAME_TOOLTIP_TEXT: string = "UI_SEARCH_SUBMITTING_ENTITY_NAME_TOOLTIP_TEXT";
export const SEARCH_SUBMITTING_ENTITY_LEI: string = "UI_SEARCH_SUBMITTING_ENTITY_LEI";
export const SEARCH_SUBMITTING_ENTITY_LEI_TOOLTIP_TEXT: string = "UI_SEARCH_SUBMITTING_ENTITY_LEI_TOOLTIP_TEXT";
export const SEARCH_SIZE_OF_SUBMITTING_ENTITY: string = "UI_SEARCH_SIZE_OF_SUBMITTING_ENTITY";
export const SEARCH_SIZE_OF_SUBMITTING_ENTITY_TOOLTIP_TEXT: string = "UI_SEARCH_SIZE_OF_SUBMITTING_ENTITY_TOOLTIP_TEXT";
export const SEARCH_LEI_STATUS: string = "UI_SEARCH_LEI_STATUS";
export const SEARCH_LEI_STATUS_TOOLTIP_TEXT: string = "UI_SEARCH_LEI_STATUS_TOOLTIP_TEXT";
export const SEARCH_INDUSTRY_SECTOR: string = "UI_SEARCH_INDUSTRY_SECTOR";
export const SEARCH_INDUSTRY_SECTOR_TOOLTIP_TEXT: string = "UI_SEARCH_INDUSTRY_SECTOR_TOOLTIP_TEXT";
export const SEARCH_LEGAL_FRAMEWORK: string = "UI_SEARCH_LEGAL_FRAMEWORK";
export const SEARCH_LEGAL_FRAMEWORK_TOOLTIP_TEXT: string = "UI_SEARCH_LEGAL_FRAMEWORK_TOOLTIP_TEXT";
export const SEARCH_COLLECTION_BODY_NAME: string = "UI_SEARCH_COLLECTION_BODY_NAME";
export const SEARCH_COLLECTION_BODY_NAME_TOOLTIP_TEXT: string = "UI_SEARCH_COLLECTION_BODY_NAME_TOOLTIP_TEXT";
export const SEARCH_HOME_MEMBER_STATE_OF_ENTITY: string = "UI_SEARCH_HOME_MEMBER_STATE_OF_ENTITY";
export const SEARCH_HOME_MEMBER_STATE_OF_ENTITY_TOOLTIP_TEXT: string = "UI_SEARCH_HOME_MEMBER_STATE_OF_ENTITY_TOOLTIP_TEXT";
export const SEARCH_HOST_MEMBER_STATE_OF_ENTITY: string = "UI_SEARCH_HOST_MEMBER_STATE_OF_ENTITY";
export const SEARCH_HOST_MEMBER_STATE_OF_ENTITY_TOOLTIP_TEXT: string = "UI_SEARCH_HOST_MEMBER_STATE_OF_ENTITY_TOOLTIP_TEXT";
export const SEARCH_ISIN: string = "UI_SEARCH_ISIN";
export const SEARCH_ISIN_TOOLTIP_TEXT: string = "UI_SEARCH_ISIN_TOOLTIP_TEXT";
export const SEARCH_FISN: string = "UI_SEARCH_FISN";
export const SEARCH_FISN_TOOLTIP_TEXT = "UI_SEARCH_FISN_TOOLTIP_TEXT";
export const SEARCH_VOLUNTARY_INFO_FLAG: string = "UI_SEARCH_VOLUNTARY_INFO_FLAG";
export const SEARCH_VOLUNTARY_INFO_FLAG_TOOLTIP_TEXT: string = "UI_SEARCH_VOLUNTARY_INFO_FLAG_TOOLTIP_TEXT";
export const SEARCH_HISTORICAL_INFO_FLAG: string = "UI_SEARCH_HISTORICAL_INFO_FLAG";
export const SEARCH_HISTORICAL_INFO_FLAG_TOOLTIP_TEXT: string = "UI_SEARCH_HISTORICAL_INFO_FLAG_TOOLTIP_TEXT";
export const SEARCH_NO_RESULTS: string = "UI_SEARCH_NO_RESULTS";




export const FILTER_KEY_MAPPING: { [key: string]: string } = {
    "Test": SEARCH_FILTERS_TEST_LABEL,
    "Type of information": SEARCH_FILTERS_TYPE_OF_INFORMATION_LABEL,
    "date range": SEARCH_FILTERS_DATE_RANGE_LABEL,
    "test text long": SEARCH_FILTERS_FREE_TEXT_LABEL,
    "Entity LEI": SEARCH_FILTERS_ENTITY_LEI,
    "Entity name": SEARCH_FILTERS_ENTITY_NAME,
    "Size of the Entity": SEARCH_FILTERS_SIZE_OF_ENTITY,
    "Submission date / time": SEARCH_FILTERS_SUBMISSION_DATE,
    "Date or period covered": SEARCH_FILTERS_DATE_COVERED,
    "Country of registered office": SEARCH_FILTERS_COUNTRY_OF_REGISTERED_OFFICE,
    "Original language(s)": SEARCH_FILTERS_ORIGINAL_LANGUAGE,
    "Submitting entity name": SEARCH_SUBMITTING_ENTITY_NAME,
    "Submitting entity LEI": SEARCH_SUBMITTING_ENTITY_LEI,
    "Size of the submitting entity": SEARCH_SIZE_OF_SUBMITTING_ENTITY,
    "LEI status": SEARCH_LEI_STATUS,
    "Industry sector(s)": SEARCH_INDUSTRY_SECTOR,
    "Legal framework": SEARCH_LEGAL_FRAMEWORK,
    "Collection Body name": SEARCH_COLLECTION_BODY_NAME,
    "Home member state of the entity concerned": SEARCH_HOME_MEMBER_STATE_OF_ENTITY,
    "Host member state of the entity concerned": SEARCH_HOST_MEMBER_STATE_OF_ENTITY,
    "ISIN": SEARCH_ISIN,
    "FISN": SEARCH_FISN,
    "Voluntary information flag": SEARCH_VOLUNTARY_INFO_FLAG,
    "Historical information flag": SEARCH_HISTORICAL_INFO_FLAG
};


// DYNAMIC ALERTS
export const SEARCH_DYNAMIC_ALERTS_FREE_TEXT: string = "UI_SEARCH_DYNAMIC_ALERTS_FREE_TEXT";
export const SEARCH_DYNAMIC_ALERTS_TYPE_OF_INFORMATION: string = "UI_SEARCH_DYNAMIC_ALERTS_TYPE_OF_INFORMATION";
export const SEARCH_DYNAMIC_ALERTS_ENTITY_LEI: string = "UI_SEARCH_DYNAMIC_ALERTS_ENTITY_LEI";
export const SEARCH_DYNAMIC_ALERTS_ENTITY_NAME: string = "UI_SEARCH_DYNAMIC_ALERTS_ENTITY_NAME";
export const SEARCH_DYNAMIC_ALERTS_SIZE_OF_ENTITY: string = "UI_SEARCH_DYNAMIC_ALERTS_SIZE_OF_ENTITY";
export const SEARCH_DYNAMIC_ALERTS_SUBMISSION_DATE_FROM: string = "UI_SEARCH_DYNAMIC_ALERTS_SUBMISSION_DATE_FROM";
export const SEARCH_DYNAMIC_ALERTS_SUBMISSION_DATE_TO: string = "UI_SEARCH_DYNAMIC_ALERTS_SUBMISSION_DATE_TO";
export const SEARCH_DYNAMIC_ALERTS_DATE_COVERED_FROM: string = "UI_SEARCH_DYNAMIC_ALERTS_DATE_COVERED_FROM";
export const SEARCH_DYNAMIC_ALERTS_DATE_COVERED_TO: string = "UI_SEARCH_DYNAMIC_ALERTS_DATE_COVERED_TO";
export const SEARCH_DYNAMIC_ALERTS_SUBMITTING_ENTITY_NAME: string = "UI_SEARCH_DYNAMIC_ALERTS_SUBMITTING_ENTITY_NAME";
export const SEARCH_DYNAMIC_ALERTS_SUBMITTING_ENTITY_LEI: string = "UI_SEARCH_DYNAMIC_ALERTS_SUBMITTING_ENTITY_LEI";
export const SEARCH_DYNAMIC_ALERTS_SIZE_OF_SUBMITTING_ENTITY: string = "UI_SEARCH_DYNAMIC_ALERTS_SIZE_OF_SUBMITTING_ENTITY";
export const SEARCH_DYNAMIC_ALERTS_LEI_STATUS: string = "UI_SEARCH_DYNAMIC_ALERTS_LEI_STATUS";
export const SEARCH_DYNAMIC_ALERTS_COUNTRY_OF_REGISTERED_OFFICE: string = "UI_SEARCH_DYNAMIC_ALERTS_COUNTRY_OF_REGISTERED_OFFICE";
export const SEARCH_DYNAMIC_ALERTS_INDUSTRY_SECTOR: string = "UI_SEARCH_DYNAMIC_ALERTS_INDUSTRY_SECTOR";
export const SEARCH_DYNAMIC_ALERTS_ORIGINAL_LANGUAGE: string = "UI_SEARCH_DYNAMIC_ALERTS_ORIGINAL_LANGUAGE";
export const SEARCH_DYNAMIC_ALERTS_LEGAL_FRAMEWORK: string = "UI_SEARCH_DYNAMIC_ALERTS_LEGAL_FRAMEWORK";
export const SEARCH_DYNAMIC_ALERTS_COLLECTION_BODY_NAME: string = "UI_SEARCH_DYNAMIC_ALERTS_COLLECTION_BODY_NAME";
export const SEARCH_DYNAMIC_ALERTS_HOME_MEMBER_STATE_OF_ENTITY: string = "UI_SEARCH_DYNAMIC_ALERTS_HOME_MEMBER_STATE_OF_ENTITY";
export const SEARCH_DYNAMIC_ALERTS_HOST_MEMBER_STATE_OF_ENTITY: string = "UI_SEARCH_DYNAMIC_ALERTS_HOST_MEMBER_STATE_OF_ENTITY";
export const SEARCH_DYNAMIC_ALERTS_ISIN: string = "UI_SEARCH_DYNAMIC_ALERTS_ISIN";
export const SEARCH_DYNAMIC_ALERTS_FISN: string = "UI_SEARCH_DYNAMIC_ALERTS_FISN";
export const SEARCH_DYNAMIC_ALERTS_VOLUNTARY_INFO_FLAG: string = "UI_SEARCH_DYNAMIC_ALERTS_VOLUNTARY_INFO_FLAG";
export const SEARCH_DYNAMIC_ALERTS_HISTORICAL_INFO_FLAG: string = "UI_SEARCH_DYNAMIC_ALERTS_HISTORICAL_INFO_FLAG";



export const DYNAMIC_ALERT_KEYS: { [key: string]: string } = {
    freeText: SEARCH_DYNAMIC_ALERTS_FREE_TEXT,
    typeOfInformation: SEARCH_DYNAMIC_ALERTS_TYPE_OF_INFORMATION,
    entityLEI: SEARCH_DYNAMIC_ALERTS_ENTITY_LEI,
    entityName: SEARCH_DYNAMIC_ALERTS_ENTITY_NAME,
    sizeOfEntity: SEARCH_DYNAMIC_ALERTS_SIZE_OF_ENTITY,
    submissionDateFrom: SEARCH_DYNAMIC_ALERTS_SUBMISSION_DATE_FROM,
    submissionDateTo: SEARCH_DYNAMIC_ALERTS_SUBMISSION_DATE_TO,
    dateCoveredFrom: SEARCH_DYNAMIC_ALERTS_DATE_COVERED_FROM,
    dateCoveredTo: SEARCH_DYNAMIC_ALERTS_DATE_COVERED_TO,
    submittingEntityName: SEARCH_DYNAMIC_ALERTS_SUBMITTING_ENTITY_NAME,
    submittingEntityLEI: SEARCH_DYNAMIC_ALERTS_SUBMITTING_ENTITY_LEI,
    sizeOfSubmittingEntity: SEARCH_DYNAMIC_ALERTS_SIZE_OF_SUBMITTING_ENTITY,
    leiStatus: SEARCH_DYNAMIC_ALERTS_LEI_STATUS,
    countryOfRegisteredOffice: SEARCH_DYNAMIC_ALERTS_COUNTRY_OF_REGISTERED_OFFICE,
    industrySector: SEARCH_DYNAMIC_ALERTS_INDUSTRY_SECTOR,
    originalLanguage: SEARCH_DYNAMIC_ALERTS_ORIGINAL_LANGUAGE,
    legalFramework: SEARCH_DYNAMIC_ALERTS_LEGAL_FRAMEWORK,
    collectionBodyName: SEARCH_DYNAMIC_ALERTS_COLLECTION_BODY_NAME,
    homeMemberStateOfEntity: SEARCH_DYNAMIC_ALERTS_HOME_MEMBER_STATE_OF_ENTITY,
    hostMemberStateOfEntity: SEARCH_DYNAMIC_ALERTS_HOST_MEMBER_STATE_OF_ENTITY,
    isin: SEARCH_DYNAMIC_ALERTS_ISIN,
    fisn: SEARCH_DYNAMIC_ALERTS_FISN,
    voluntaryInfoFlag: SEARCH_DYNAMIC_ALERTS_VOLUNTARY_INFO_FLAG,
    historicalInfoFlag: SEARCH_DYNAMIC_ALERTS_HISTORICAL_INFO_FLAG
};

// SEARCH RESULTS
export const SEARCH_RESULTS_PAGING_OF = "UI_SEARCH_RESULTS_PAGING_OF";
export const SEARCH_RESULTS = "UI_SEARCH_RESULTS_PAGING_ITEMS_RESULTS";
export const SEARCH_RESULTS_SORT_BY = "UI_SEARCH_RESULTS_SORT_BY";
export const SEARCH_RESULTS_SORT_BY_RELEVANCE = "UI_SEARCH_RESULTS_SORT_BY_RELEVANCE";
export const SEARCH_RESULTS_SORT_BY_SUBMISSION_DATE = "UI_SEARCH_RESULTS_SORT_BY_SUBMISSION_DATE";
export const SEARCH_RESULTS_SORT_BY_PERIOD_COVERED = "UI_SEARCH_RESULTS_SORT_BY_PERIOD_COVERED";
export const SEARCH_RESULTS_SORT_BY_INFO_TYPE = "UI_SEARCH_RESULTS_SORT_BY_INFO_TYPE";
export const SEARCH_RESULTS_SHOW = "UI_SEARCH_RESULTS_SHOW";
export const SEARCH_RESULTS_DOWNLOAD_DATA_SET = "UI_SEARCH_RESULTS_DOWNLOAD_DATA_SET";
export const SEARCH_RESULTS_ADD_DATA_SET_TO_CART = "UI_SEARCH_RESULTS_DOWNLOAD_ADD_DATA_SET_TO_CART";
export const SEARCH_RESULTS_ADD_DATA_LIMIT_REACHED = "UI_SEARCH_RESULTS_ADD_DATA_LIMIT_REACHED";
export const SEARCH_RESULTS_REMOVE_DATA_SET_FROM_CART = "UI_SEARCH_RESULTS_DOWNLOAD_REMOVE_DATA_SET_FROM_CART";
export const SEARCH_RESULTS_ACCESS_INFO_VIEWER = "UI_SEARCH_RESULTS_ACCESS_INFO_VIEWER";
export const SEARCH_RESULTS_ELECTRONIC_SEAL = "UI_SEARCH_RESULTS_ELECTRONIC_SEAL";
export const SEARCH_RESULTS_INFO_RELATED_TO_HISTORICAL_DATA = "UI_SEARCH_RESULTS_INFO_RELATED_TO_HISTORICAL_DATA";
export const SEARCH_RESULTS_INFO_RELATED_TO_VOLUNTARY_DATA = "UI_SEARCH_RESULTS_INFO_RELATED_TO_VOLUNTARY_DATA";
export const SEARCH_RESULTS_PAGINATION_FOOTER_FIRST = "UI_SEARCH_RESULTS_PAGINATION_FOOTER_FIRST";
export const SEARCH_RESULTS_PAGINATION_FOOTER_PREVIOUS = "UI_SEARCH_RESULTS_PAGINATION_FOOTER_PREVIOUS";
export const SEARCH_RESULTS_PAGINATION_FOOTER_NEXT = "UI_SEARCH_RESULTS_PAGINATION_FOOTER_NEXT";
export const SEARCH_RESULTS_PAGINATION_FOOTER_LAST = "UI_SEARCH_RESULTS_PAGINATION_FOOTER_LAST";

// REPORTING ENTITIES
export const RE_HEADER_TEXT: string = "UI_RE_HEADER_TEXT";
export const RE_TYPE_OF_DATA_LABEL: string = "UI_RE_TYPE_OF_DATA_LABEL";
export const RE_TYPE_OF_DATA_TOOLTIP_TEXT: string = "UI_RE_TYPE_OF_DATA_TOOLTIP_TEXT";
export const RE_SYSTEM_VERSION_LABEL: string = "UI_RE_SYSTEM_VERSION_LABEL";
export const RE_SYSTEM_VERSION_TOOLTIP_TEXT: string = "UI_RE_SYSTEM_VERSION_TOOLTIP_TEXT";
export const RE_VERSION_RELATED_DOCUMENTATION_BUTTON_TEXT: string = "UI_RE_VERSION_RELATED_DOCUMENTATION_BUTTON_TEXT";
export const RE_TEST_DATA_LABEL: string = "UI_RE_TEST_DATA_LABEL";
export const RE_FILE_UPLOAD_TOOLTIP_TEXT: string = "UI_RE_FILE_UPLOAD_TOOLTIP_TEXT";
export const RE_FILE_UPLOAD_HELPER_TEXT: string = "UI_RE_FILE_UPLOAD_HELPER_TEXT";
export const RE_UPLOAD_TEST_DATA_BUTTON_TEXT: string = "UI_RE_UPLOAD_TEST_DATA_BUTTON_TEXT";
export const RE_UPLOADING_TEST_DATA_TEXT: string = "UI_RE_UPLOADING_TEST_DATA_TEXT";
export const RE_UPLOAD_DATA_BUTTON_TEXT: string = "UI_RE_UPLOAD_DATA_BUTTON_TEXT";
export const RE_UPLOADING_DATA_TEXT: string = "UI_RE_UPLOADING_DATA_TEXT";

// IMPORTANT ANNOUNCEMENTS
export const IMPORTANT_ANNOUNCEMENTS_LABEL: string = "UI_IMPORTANT_ANNOUNCEMENTS_LABEL";
export const IMPORTANT_ANNOUNCEMENTS_TEXT: string = "UI_IMPORTANT_ANNOUNCEMENTS_TEXT";



// HISTORY OF SUBMISSIONS
export const HS_FILE_UPLOAD_AND_HISTORY_OF_SUBMISSIONS: string = "UI_HS_FILE_UPLOAD_AND_HISTORY_OF_SUBMISSIONS"
export const HS_HISTORY_OF_SUBMISSIONS: string = "UI_HS_HISTORY_OF_SUBMISSIONS";
export const HS_FILTER_HISTORY_OF_SUBMISSIONS: string = "UI_HS_FILTER_HISTORY_OF_SUBMISSIONS";
export const HS_DOWNLOAD_THE_FILE: string = "UI_HS_DOWNLOAD_THE_FILE"
export const HS_DOWNLOAD_FEEDBACK: string = "UI_HS_DOWNLOAD_FEEDBACK"
export const HS_RESEND_FEEDBACK: string = "UI_HS_RESEND_FEEDBACK"
export const HS_VALIDATIONS: string = "UI_HS_VALIDATIONS"
export const HS_APPLY_FILTERS: string = "UI_HS_APPLY_FILTERS"
export const HS_RESET_FILTERS: string = "UI_HS_RESET_FILTERS"
export const HS_PROCESSING_TIME: string = "UI_HS_PROCESSING_TIME";
export const HS_METADATA: string = "UI_HS_METADATA";
export const HS_DATA: string = "UI_HS_DATA";
export const HS_QUALIFIED_ELECTRONIC_SEALS: string = "UI_HS_QUALIFIED_ELECTRONIC_SEALS";
export const HS_VALIDATION: string = "UI_HS_VALIDATION";
export const HS_ERROR_CODES: string = "UI_HS_ERROR_CODES";
export const HS_FEEDBACK_FILE_NAME: string = "UI_HS_FEEDBACK_FILE_NAME";
export const HS_DATASET_STATUS: string = "UI_HS_DATASET_STATUS";
export const HS_FILETYPECODE: string = "UI_HS_FILETYPECODE";
export const HS_PATH_TO_THE_REJECTED_DATA: string = "UI_HS_PATH_TO_THE_REJECTED_DATA";
export const HS_UPLOAD_FILE: string = "UI_HS_UPLOAD_FILE";
export const HS_CHOOSE_FILE: string = "UI_HS_CHOOSE_FILE";
export const HS_PROCESSING_START_END_DATE: string="UI_HS_PROCESSING_START_END_DATE";
export const HS_RECEPTION_DATE: string ="UI_HS_RECEPTION_DATE";
export const HS_DETAILS: string ="UI_HS_DETAILS";
export const HS_FILENAME: string ="UI_HS_FILENAME";
export const HS_VALIDATION_OUTCOME: string ="UI_HS_VALIDATION_OUTCOME";
export const HS_ELECTRONICALLY_SEALED: string ="UI_HS_ELECTRONICALLY_SEALED";
export const HS_VALIDATION_STATUS: string ="UI_HS_VALIDATION_STATUS";
export const HS_FILE_DETAILS: string ="UI_HS_FILE_DETAILS";
export const HS_ERROR: string ="UI_HS_ERROR";
export const HS_HELP_WITH_THIS_FILE: string = "UI_HS_HELP_WITH_THIS_FILE"

// FILE RE-SUBMISSION
export const HS_FILE_RE_SUBMISSION: string ="UI_HS_FILE_RE_SUBMISSION";
export const HS_PATH_WHERE_THE_FILES_TO_BE_REPROCESSED_ARE_LOCATED: string = "UI_HS_PATH_WHERE_THE_FILES_TO_BE_REPROCESSED_ARE_LOCATED";
export const HS_REPROCESS_FILES: string = "UI_HS_REPROCESS_FILES";
export const HS_LIST_OF_FILES_TO_BE_REPROCESSED: string = "UI_HS_LIST_OF_FILES_TO_BE_REPROCESSED";

// FOOTER
export const DISCLAIMER_LABEL: string = "UI_DISCLAIMER_LABEL";
export const LEGAL_NOTICE_LABEL: string = "UI_LEGAL_NOTICE_LABEL";

export const Roles = {
    WEB_PORTAL_ROLE: 'WebPortalRole',
    SEARCH_ENGINE_ROLE: 'SearchEngineRole',
    DOWNLOAD_SERVICE_ROLE: 'DownloadServiceRole',
    INFORMATION_VIEWER_ROLE: 'InformationViewerRole',
    TRANSLATION_ROLE: 'TranslationRole',
    NOTIFICATION_ROLE: 'NotificationRole',
    DATA_FLOW_ROLE: 'DataFlowRole',
    ESAP_SUPPORT_ROLE: 'ESAPSupportRole',
    ESAP_DATA_MANAGER_ROLE: 'ESAPDataManagerRole',
    ESAP_DATA_ADMINISTRATOR_ROLE: 'ESAPDataAdministratorRole',
};

export type Role = (typeof Roles)[keyof typeof Roles];

type RoleKey = typeof Roles[keyof typeof Roles];

export const ReadableRoleNames: Record<RoleKey, string> = {
    WebPortalRole: 'Web Portal',
    SearchEngineRole: 'Search Engine',
    DownloadServiceRole: 'Download Service',
    InformationViewerRole: 'Information Viewer',
    TranslationRole: 'Translation',
    NotificationRole: 'Notification',
    DataFlowRole: 'Data Flow',
    ESAPSupportRole: 'ESAP Support',
    ESAPDataManagerRole: 'ESAP Data Manager',
    ESAPDataAdministratorRole: 'ESAP Data Administrator',
};

// MONITORING EVENTS
export const CustomEvents = {
    VIEW_DATASET: "viewDatasetEvent",
    DOWNLOAD_DATASET: "downloadDatasetEvent",
    VIEW_FILE: "viewFileEvent",
    DOWNLOAD_FILE: "downloadFileEvent",
    VISITOR: "visitorEvent",
    SEARCH: "searchEvent",
    FREE_TEXT_SEARCH: "searchTermEvent",
    DATE_COVERED: "dateCoveredFilterEvent",
    FISN: "fisnFilterEvent",
    ISIN: "isinFilterEvent",
    ENTITY_LEI: "entityLeiFilterEvent",
    ENTITY_NAME: "entityNameFilterEvent",
    ENTITY_LEI_STATUS: "entityLeiStatusFilterEvent",
    ENTITY_SIZE: "entitySizeFilterEvent",
    SUBMISSION_DATE: "submissionDateFilterEvent",
    TYPE_OF_INFORMATION: "typeOfInformationFilterEvent",
    COLLECTION_BODY: "collectionBodyFilterEvent",
    COUNTRY_OF_OFFICE: "countryOfOfficeFilterEvent",
    HOME_MEMBER_STATE: "homeMemberStateFilterEvent",
    HOST_MEMBER_STATE: "hostMemberStateFilterEvent",
    INDUSTRY_SECTOR: "industrySectorFilterEvent",
    LEGAL_FRAMEWORK: "legalFrameworkFilterEvent",
    ORIGINAL_LANGUAGE: "originalLanguageFilterEvent",
    SUBMITTING_ENTITY_SIZE: "submitEntitySizeFilterEvent",
    SUBMITTING_ENTITY_LEI: "entityLeiFilterEvent",
    SUBMITTING_ENTITY_NAME: "entityNameFilterEvent",
    // HISTORICAL_INFO_FLAG: "historicalInfoFlagMoreFilterEvent",
    // VOLUNTARY_INFO_FLAG: "voluntaryInfoFlagMoreFilterEvent"
}
export const CUSTOM_EVENTS_PREFIXES = {
    TYPE_OF_INFORMATION: "typeOfInformation_",
    LEGAL_FRAMEWORK: "legalFramework_",
}

export const DataFlowTypes = {
    DATPRI: "DATPRI",
    DATPXD: "DATPXD",
    DATTRD : "DATTRD",
    DATSSR : "DATSSR"
}

export const TranslationStatus = {
    NOT_STARTED: "NotStarted",
    RUNNING: "Running",
    SUCCEEDED: "Succeeded",
    FAILED: "Failed",
    VALIDATION_FAILED: "ValidationFailed",
    PROCESSING_ZIP: "ProcessingZip",
}


// MONITORING
// Data Quality Common Table --
export const MONITORING_DATA_QUALITY_TABLE_NO_RESULTS: string = "UI_MONITORING_DATA_QUALITY_TABLE_NO_RESULTS";
export const MONITORING_DATA_QUALITY_TABLE_NO_QUALITY_ISSUES: string = "UI_MONITORING_DATA_QUALITY_TABLE_NO_QUALITY_ISSUES";
export const MONITORING_DATA_QUALITY_TABLE_LOADING_ISSUES: string = "UI_MONITORING_DATA_QUALITY_TABLE_LOADING_ISSUES";
export const MONITORING_DATA_QUALITY_TABLE_SAVE_BUTTON_TEXT: string = "UI_MONITORING_DATA_QUALITY_TABLE_SAVE_BUTTON_TEXT";
// -- Data Quality Latest --
export const MONITORING_DATA_QUALITY_TITLE: string = "UI_MONITORING_DATA_QUALITY_TITLE";
export const MONITORING_DATA_QUALITY_REPORT_IDENTIFIER: string = "UI_MONITORING_DATA_QUALITY_REPORT_IDENTIFIER";
export const MONITORING_DATA_QUALITY_HISTORICAL_DATE_OF_REPORT: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_DATE_OF_REPORT"
export const MONITORING_DATA_QUALITY_TABLE_HEADER_DATASET_ID: string = "UI_MONITORING_DATA_QUALITY_TABLE_HEADER_DATASET_ID";
export const MONITORING_DATA_QUALITY_TABLE_HEADER_FILETYPE_CODE: string = "UI_MONITORING_DATA_QUALITY_TABLE_HEADER_FILETYPE_CODE";
export const MONITORING_DATA_QUALITY_TABLE_HEADER_DATE_TIME: string = "UI_MONITORING_DATA_QUALITY_TABLE_HEADER_DATE_TIME";
export const MONITORING_DATA_QUALITY_TABLE_HEADER_ERROR_MSG: string = "UI_MONITORING_DATA_QUALITY_TABLE_HEADER_ERROR_MSG";
export const MONITORING_DATA_QUALITY_TABLE_HEADER_PUBLICATION_STATUS: string = "UI_MONITORING_DATA_QUALITY_TABLE_HEADER_PUBLICATION_STATUS";
export const MONITORING_DATA_QUALITY_TABLE_HEADER_SEAL_VALIDATION_STATUS: string = "UI_MONITORING_DATA_QUALITY_TABLE_HEADER_SEAL_VALIDATION_STATUS";
export const MONITORING_DATA_QUALITY_TABLE_HEADER_ACKNOWLEDGEMENT_STATUS: string = "UI_MONITORING_DATA_QUALITY_TABLE_HEADER_ACKNOWLEDGEMENT_STATUS";
export const MONITORING_DATA_QUALITY_TABLE_HEADER_PRIORITY: string = "UI_MONITORING_DATA_QUALITY_TABLE_HEADER_PRIORITY";
export const MONITORING_DATA_QUALITY_TABLE_HEADER_STATUS: string = "UI_MONITORING_DATA_QUALITY_TABLE_HEADER_STATUS";
export const MONITORING_DATA_QUALITY_TABLE_PAGINATION_TEXT_1: string = "UI_MONITORING_DATA_QUALITY_TABLE_PAGINATION_TEXT_1";
export const MONITORING_DATA_QUALITY_TABLE_PAGINATION_TEXT_2: string = "UI_MONITORING_DATA_QUALITY_TABLE_PAGINATION_TEXT_2";
export const MONITORING_DATA_QUALITY_TABLE_PAGINATION_TEXT_3: string = "UI_MONITORING_DATA_QUALITY_TABLE_PAGINATION_TEXT_3";
export const MONITORING_DATA_QUALITY_FETCH_LATEST_REPORT_FAILED_MESSAGE: string = "UI_MONITORING_DATA_QUALITY_FETCH_LATEST_REPORT_FAILED_MESSAGE";
// -- Data Quality Historical -- 
export const MONITORING_DATA_QUALITY_HISTORICAL_TITLE: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_TITLE";
export const MONITORING_DATA_QUALITY_HISTORICAL_CURRENT_REPORT_IDENTIFIER: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_CURRENT_REPORT_IDENTIFIER";
export const MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_DATASET_ID: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_DATASET_ID";
export const MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_FILETYPE_CODE: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_FILETYPE_CODE";
export const MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_DATE_TIME: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_DATE_TIME";
export const MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_ERROR_MSG: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_ERROR_MSG";
export const MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_PUBLICATION_STATUS: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_PUBLICATION_STATUS";
export const MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_SEAL_VALIDATION_STATUS: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_SEAL_VALIDATION_STATUS";
export const MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_ACKNOWLEDGEMENT_STATUS: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_ACKNOWLEDGEMENT_STATUS";
export const MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_PRIORITY: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_PRIORITY";
export const MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_STATUS: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_STATUS";
export const MONITORING_DATA_QUALITY_HISTORICAL_TABLE_PAGINATION_TEXT_1: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_TABLE_PAGINATION_TEXT_1";
export const MONITORING_DATA_QUALITY_HISTORICAL_TABLE_PAGINATION_TEXT_2: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_TABLE_PAGINATION_TEXT_2";
export const MONITORING_DATA_QUALITY_HISTORICAL_TABLE_PAGINATION_TEXT_3: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_TABLE_PAGINATION_TEXT_3";
export const MONITORING_DATA_QUALITY_HISTORICAL_FETCH_REPORT_FAILED_MESSAGE: string = "UI_MONITORING_DATA_QUALITY_HISTORICAL_FETCH_REPORT_FAILED_MESSAGE";
// -- Suspend/Resume Results --
export const MONITORING_SUSPEND_RESUME_RESULTS_TITLE: string = "UI_MONITORING_SUSPEND_RESUME_RESULTS_TITLE";
export const MONITORING_SUSPEND_RESUME_FETCH_RESULTS_FAILED_MESSAGE: string = "UI_MONITORING_SUSPEND_RESUME_FETCH_RESULTS_FAILED_MESSAGE"
export const MONITORING_SUSPEND_RESUME_TABLE_HEADER_DATASET_ID: string = "UI_MONITORING_SUSPEND_RESUME_TABLE_HEADER_DATASET_ID"
export const MONITORING_SUSPEND_RESUME_TABLE_HEADER_VERSION: string = "UI_MONITORING_SUSPEND_RESUME_TABLE_HEADER_VERSION"
export const MONITORING_SUSPEND_RESUME_TABLE_HEADER_DATE: string = "UI_MONITORING_SUSPEND_RESUME_TABLE_HEADER_DATE"
export const MONITORING_SUSPEND_RESUME_TABLE_HEADER_PUBLICATION_STATUS: string = "UI_MONITORING_SUSPEND_RESUME_TABLE_HEADER_PUBLICATION_STATUS"
export const MONITORING_SUSPEND_RESUME_TABLE_HEADER_SUSPEND_RESUME_STATUS: string = "UI_MONITORING_SUSPEND_RESUME_TABLE_HEADER_SUSPEND_RESUME_STATUS"
export const MONITORING_SUSPEND_RESUME_CURRENT_REPORT_IDENTIFIER: string = "UI_MONITORING_SUSPEND_RESUME_CURRENT_REPORT_IDENTIFIER";
export const MONITORING_SUSPEND_RESUME_TABLE_PAGINATION_TEXT_1: string = "UI_MONITORING_SUSPEND_RESUME_TABLE_PAGINATION_TEXT_1";
export const MONITORING_SUSPEND_RESUME_TABLE_PAGINATION_TEXT_2: string = "UI_MONITORING_SUSPEND_RESUME_TABLE_PAGINATION_TEXT_2";
export const MONITORING_SUSPEND_RESUME_TABLE_PAGINATION_TEXT_3: string = "UI_MONITORING_SUSPEND_RESUME_TABLE_PAGINATION_TEXT_3";
export const MONITORING_SUSPEND_RESUME_TABLE_NO_RESULTS: string = "UI_MONITORING_SUSPEND_RESUME_TABLE_NO_RESULTS"
export const MONITORING_SUSPEND_RESUME_TABLE_DOWNLOAD_NOT_UPDATED: string = "UI_MONITORING_SUSPEND_RESUME_TABLE_DOWNLOAD_NOT_UPDATED"
export const MONITORING_SUSPEND_RESUME_TABLE_SEARCH_NOT_UPDATED: string = "UI_MONITORING_SUSPEND_RESUME_TABLE_SEARCH_NOT_UPDATED"
export const MONITORING_SUSPEND_RESUME_TABLE_BOTH_SEARCH_AND_DOWNLOAD_NOT_UPDATED: string = "UI_MONITORING_SUSPEND_RESUME_TABLE_BOTH_SEARCH_AND_DOWNLOAD_NOT_UPDATED"
// -- Availability Monitoring Results --
export const MONITORING_AVAILABILITY_MONITORING_LABEL : string = "UI_MONITORING_AVAILABILITY_MONITORING_LABEL ";
export const MONITORING_AVAILABILITY_TABLE_HEADER_AVAILABILITY_STATUS: string = "UI_MONITORING_AVAILABILITY_TABLE_HEADER_AVAILABILITY_STATUS";
export const MONITORING_AVAILABILITY_TABLE_HEADER_DOWNLOADABILITY_STATUS: string = "UI_MONITORING_AVAILABILITY_TABLE_HEADER_DOWNLOADABILITY_STATUS";
export const MONITORING_AVAILABILITY_TABLE_HEADER_CHECK_TIMESTAMP: string = "UI_MONITORING_AVAILABILITY_TABLE_HEADER_CHECK_TIMESTAMP";
export const MONITORING_AVAILABILITY_FETCH_RESULTS_FAILED_MESSAGE: string = "UI_MONITORING_AVAILABILITY_FETCH_RESULTS_FAILED_MESSAGE";
export const MONITORING_AVAILABILITY_FETCH_TIMESTAMP_FAILED_MESSAGE: string = "UI_MONITORING_AVAILABILITY_FETCH_TIMESTAMP_FAILED_MESSAGE";
export const MONITORING_AVAILABILITY_TABLE_PAGINATION_TEXT_1 : string = "UI_MONITORING_AVAILABILITY_TABLE_PAGINATION_TEXT_1";
export const MONITORING_AVAILABILITY_TABLE_PAGINATION_TEXT_2 : string = "UI_MONITORING_AVAILABILITY_TABLE_PAGINATION_TEXT_2";
export const MONITORING_AVAILABILITY_TABLE_PAGINATION_TEXT_3 : string = "UI_MONITORING_AVAILABILITY_TABLE_PAGINATION_TEXT_3";
export const MONITORING_AVAILABILITY_TABLE_NO_RESULTS : string = "UI_MONITORING_AVAILABILITY_TABLE_NO_RESULTS";
export const MONITORING_AVAILABILITY_DATE_OF_EXECUTION_MESSAGE = "UI_MONITORING_AVAILABILITY_DATE_OF_EXECUTION_MESSAGE";

// INFORMATION VIEWER - DOWNLOAD SERVICE
export const FileTypes = {
    XML: "XML",
    XBRL: "XBRL",
    ESEF_ZIP: "ESEF_ZIP",
    CSV: "CSV",
    TXT: "TXT",
    PDF: "PDF",
    JSON: "JSON",
    HTML: "HTML",
    XHTML: "XHTML"
}


export const INFOVIEWER_METADATA_REGULATORYDATA_CLASSIFICATION: string = "UI_INFOVIEWER_METADATA_REGULATORYDATA_CLASSIFICATION";
export const INFOVIEWER_METADATA_LEGALFRAMEWORK_CODE: string = "UI_INFOVIEWER_METADATA_LEGALFRAMEWORK_CODE";
export const INFOVIEWER_METADATA_NACE_TAXONOMY_SECTOR: string = "UI_INFOVIEWER_METADATA_NACE_TAXONOMY_SECTOR";

//Dataset Details Page
export const INFOVIEWER_DATASET_DETAILS_SELECT_DOCUMENT: string = "UI_INFOVIEWER_DATASET_DETAILS_SELECT_DOCUMENT";
export const INFOVIEWER_DATASET_DETAILS_DOWNLOADING_SPINNER: string = "UI_INFOVIEWER_DATASET_DETAILS_DOWNLOADING_SPINNER";
export const INFOVIEWER_DATASET_DETAILS_DOWNLOAD_BUTTON: string = "UI_INFOVIEWER_DATASET_DETAILS_DOWNLOAD_BUTTON";
export const INFOVIEWER_DATASET_DETAILS_TRANSLATE_BUTTON: string = "UI_INFOVIEWER_DATASET_DETAILS_TRANSLATE_BUTTON";
export const INFOVIEWER_DATASET_DETAILS_TRANSLATE_SPINNER: string = "UI_INFOVIEWER_DATASET_DETAILS_TRANSLATE_SPINNER";
export const INFOVIEWER_DATASET_DETAILS_IN_CART_BUTTON: string = "UI_INFOVIEWER_DATASET_DETAILS_IN_CART_BUTTON";
export const INFOVIEWER_DATASET_DETAILS_ADD_TO_CART_BUTTON: string = "UI_INFOVIEWER_DATASET_DETAILS_ADD_TO_CART_BUTTON";
export const INFOVIEWER_DATASET_DETAILS_VIEW_BUTTON: string = "UI_INFOVIEWER_DATASET_DETAILS_VIEW_BUTTON";
export const INFOVIEWER_DATASET_DETAILS_SELECT_FILE_MESSAGE: string = "UI_INFOVIEWER_DATASET_SELECT_FILE_MESSAGE";
export const INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_FIRST_BUTTON: string = "UI_INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_FIRST_BUTTON";
export const INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_NEXT_BUTTON: string = "UI_INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_NEXT_BUTTON";
export const INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_LAST_BUTTON: string = "UI_INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_LAST_BUTTON";
export const INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_PREVIOUS_BUTTON: string = "UI_INFOVIEWER_DATASET_DETAILS_CSV_DISPLAY_PREVIOUS_BUTTON";
export const INFOVIEWER_DATASET_DETAILS_DOWNLOAD_DATASET_ERROR_MESSAGE: string = "UI_INFOVIEWER_DATASET_DETAILS_DOWNLOAD_DATASET_ERROR_MESSAGE";
export const INFOVIEWER_DATASET_DETAILS_FETCH_FILE_ERROR_MESSAGE: string = "UI_INFOVIEWER_DATASET_DETAILS_FETCH_FILE_ERROR_MESSAGE";
export const INFOVIEWER_DATASET_DETAILS_FETCH_DATASET_ERROR_MESSAGE: string = "UI_INFOVIEWER_DATASET_DETAILS_FETCH_DATASET_ERROR_MESSAGE";
//Metadata Fields
export const INFOVIEWER_METADATA_CARD_COLLECTION_BODY_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_COLLECTION_BODY_FIELD";
export const INFOVIEWER_METADATA_CARD_ENTITY_LEI_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_ENTITY_LEI_FIELD";
export const INFOVIEWER_METADATA_CARD_ENTITY_NAME_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_ENTITY_NAME_FIELD";
export const INFOVIEWER_METADATA_CARD_ENTITY_COUNTRY_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_ENTITY_COUNTRY_FIELD";
export const INFOVIEWER_METADATA_CARD_ENTITY_SIZE_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_ENTITY_SIZE_FIELD";
export const INFOVIEWER_METADATA_CARD_ENTITY_SECTOR_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_ENTITY_SECTOR_FIELD";
export const INFOVIEWER_METADATA_CARD_TYPE_OF_INFORMATION_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_TYPE_OF_INFORMATION_FIELD";
export const INFOVIEWER_METADATA_CARD_LEGAL_FRAMEWORK_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_LEGAL_FRAMEWORK_FIELD";
export const INFOVIEWER_METADATA_CARD_SUBMISSION_DATE_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_SUBMISSION_DATE_FIELD";
export const INFOVIEWER_METADATA_CARD_HOME_MEMBER_STATE_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_HOME_MEMBER_STATE_FIELD";
export const INFOVIEWER_METADATA_CARD_HOST_MEMBER_STATE_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_HOST_MEMBER_STATE_FIELD";
export const INFOVIEWER_METADATA_CARD_VERSION_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_VERSION_FIELD";
export const INFOVIEWER_METADATA_CARD_APPROVAL_DATE_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_APPROVAL_DATE_FIELD";
export const INFOVIEWER_METADATA_CARD_EU_GROWTH_PROSPECTUS_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_EU_GROWTH_PROSPECTUS_FIELD";
export const INFOVIEWER_METADATA_CARD_SECURITIES_TYPES_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_SECURITIES_TYPES_FIELD";
export const INFOVIEWER_METADATA_CARD_CONSIDERATION_OFFERED_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_CONSIDERATION_OFFERED_FIELD";
export const INFOVIEWER_METADATA_CARD_PROSPECTUS_TYPE_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_PROSPECTUS_TYPE_FIELD";
export const INFOVIEWER_METADATA_CARD_PASSPORTED_INDICATOR_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_PASSPORTED_INDICATOR_FIELD";
export const INFOVIEWER_METADATA_CARD_ISIN_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_ISIN_FIELD";
export const INFOVIEWER_METADATA_CARD_PERSONAL_DATA_FIELD: string = "UI_INFOVIEWER_METADATA_CARD_PERSONAL_DATA_FIELD";
export const INFOVIEWER_METADATA_INDUSTRY_SECTOR_OF_POSITION_HOLDER_FIELD: string = "UI_INFOVIEWER_METADATA_INDUSTRY_SECTOR_OF_POSITION_HOLDER_FIELD";
export const INFOVIEWER_METADATA_CARD_MORE_METADATA_LABEL: string = "UI_INFOVIEWER_METADATA_CARD_MORE_METADATA_LABEL";
//Drupal Icons
export const INFOVIEWER_DRUPAL_ICONS_E_SEALED_TOOLTIP: string = "UI_INFOVIEWER_DRUPAL_ICONS_E_SEALED_TOOLTIP";
export const INFOVIEWER_DRUPAL_ICONS_HISTORICAL_TOOLTIP: string = "UI_INFOVIEWER_DRUPAL_ICONS_HISTORICAL_TOOLTIP";
export const INFOVIEWER_DRUPAL_ICONS_VOLUNTARY_TOOLTIP: string = "UI_INFOVIEWER_DRUPAL_ICONS_VOLUNTARY_TOOLTIP";
export const INFOVIEWER_DRUPAL_ICONS_HISTORICAL_LABEL: string = "UI_INFOVIEWER_DRUPAL_ICONS_HISTORICAL_LABEL";
export const INFOVIEWER_DRUPAL_ICONS_VOLUNTARY_LABEL: string = "UI_INFOVIEWER_DRUPAL_ICONS_VOLUNTARY_LABEL";
//Cart
export const DOWNLOAD_CART_ADD_TO_CART_MESSAGE: string = "UI_DOWNLOAD_CART_ADD_TO_CART_MESSAGE";
export const DOWNLOAD_CART_REMOVE_FROM_CART_MESSAGE: string = "UI_DOWNLOAD_CART_REMOVE_FROM_CART_MESSAGE";
export const DOWNLOAD_CART_BULK_AVAILABILITY_MESSAGE: string = "UI_DOWNLOAD_CART_BULK_AVAILABILITY_MESSAGE";
export const DOWNLOAD_CART_BULK_AVAILABILITY_ERROR_MESSAGE: string = "UI_DOWNLOAD_CART_BULK_AVAILABILITY_ERROR_MESSAGE";
export const DOWNLOAD_CART_VALIDATION_FAIL_MESSAGE: string = "UI_DOWNLOAD_CART_VALIDATION_FAIL_MESSAGE";
export const DOWNLOAD_CART_MY_CART_LABEL: string = "UI_DOWNLOAD_CART_MY_CART_LABEL";
export const DOWNLOAD_CART_HOLD_WHILE_VALIDATE_MESSAGE: string = "UI_DOWNLOAD_CART_HOLD_WHILE_VALIDATE_MESSAGE";
export const DOWNLOAD_CART_REMOVE_DATASET_TOOLTIP: string = "UI_DOWNLOAD_CART_REMOVE_DATASET_TOOLTIP";
export const DOWNLOAD_CART_REMOVE_DATASET_BUTTON: string = "UI_DOWNLOAD_CART_REMOVE_DATASET_BUTTON";
export const DOWNLOAD_CART_CURRENTLY_EMPTY_CART_MESSAGE: string = "UI_DOWNLOAD_CART_CURRENTLY_EMPTY_CART_MESSAGE";
export const DOWNLOAD_CART_ADD_ITEMS_MESSAGE: string = "UI_DOWNLOAD_CART_ADD_ITEMS_MESSAGE";
export const DOWNLOAD_CART_PROCESS_SPINNER: string = "UI_DOWNLOAD_CART_PROCESS_SPINNER";
export const DOWNLOAD_CART_CHECKOUT_BUTTON: string = "UI_DOWNLOAD_CART_CHECKOUT_BUTTON";
export const DOWNLOAD_CART_CONFIRM_CHECKOUT_MODAL_TITLE: string = "UI_DOWNLOAD_CART_CONFIRM_CHECKOUT_MODAL_TITLE";
export const DOWNLOAD_CART_CONFIRM_CHECKOUT_MODAL_BODY: string = "UI_DOWNLOAD_CART_CONFIRM_CHECKOUT_MODAL_BODY";
export const DOWNLOAD_CART_CONFIRM_BUTTON: string = "UI_DOWNLOAD_CART_CONFIRM_BUTTON";
export const DOWNLOAD_CART_CANCEL_BUTTON: string = "UI_DOWNLOAD_CART_CANCEL_BUTTON";
export const DOWNLOAD_CART_DOWNLOAD_SPINNER: string = "UI_DOWNLOAD_CART_DOWNLOAD_SPINNER";
export const DOWNLOAD_CART_LIMITS_NUMBER_MESSAGE: string = "UI_DOWNLOAD_CART_LIMITS_NUMBER_MESSAGE";
export const DOWNLOAD_CART_LIMITS_SIZE_MESSAGE: string = "UI_DOWNLOAD_CART_LIMITS_SIZE_MESSAGE";
//Dataset Details Service
export const DOWNLOAD_CART_LIMITS_EXCEEDED_NUMBER_MESSAGE: string = "UI_DOWNLOAD_CART_LIMITS_EXCEEDED_NUMBER_MESSAGE";
export const DOWNLOAD_CART_LIMITS_EXCEEDED_VOLUME_MESSAGE: string = "UI_DOWNLOAD_CART_LIMITS_EXCEEDED_VOLUME_MESSAGE";
export const DOWNLOAD_CART_LIMITS_UNAIVAILABLE_DATASETS_MESSAGE: string = "UI_DOWNLOAD_CART_LIMITS_UNAIVAILABLE_DATASETS_MESSAGE"